import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./auth";

import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Tracking from "./Pages/Tracking";
import JadLog from "./Pages/JadLog";
import Translloyds from "./Pages/Translloyds";

import ConfigurationFormEdit from "./Pages/Configuration/edit.js";

import ShippingCompany from "./Pages/ShippingCompany";
import ShippingCompanyFormEdit from "./Pages/ShippingCompany/edit.js";

import Invoice from "./Pages/Invoice";

import Store from "./Pages/Store";
import StoreForm from "./Pages/Store/form.js";

import Product from "./Pages/Product";
import ProductFormEdit from "./Pages/Product/edit.js";
import ProductFormNew from "./Pages/Product/new.js";

import EmailText from "./Pages/EmailText";
import EmailTextFormEdit from "./Pages/EmailText/edit.js";

import Requester from "./Pages/Requester";
import RequesterFormEdit from "./Pages/Requester/edit.js";
import RequesterFormNew from "./Pages/Requester/new.js";
import RequesterSync from "./Pages/Requester/sync";

import Order from "./Pages/Order";
import OrderFormConference from "./Pages/Order/conference.js";
import OrderFormEdit from "./Pages/Order/edit.js";
import OrderImport from "./Pages/Order/import.js";
import OrderToInvoiceCreate from "./Pages/Order/orderToInvoiceCreate";

import UserGroup from "./Pages/UserGroup";
import UserGroupForm from "./Pages/UserGroup/form";

import User from "./Pages/User";
import UserForm from "./Pages/User/form";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                    <Redirect
                        to={{ pathname: "/login", state: { from: props.location } }}
                    />
                )
        }
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/rastreamento-correios/:id" component={Tracking} />

            <Route path="/jadlog/:danfe" component={JadLog} />

            <Route path="/translloyds/:invoiceNumber/:serie" component={Translloyds} />

            <Route path="/correios/:id" component={Tracking} />
            <Route path="/rastreamento-correios/:id" component={Tracking} />

            <PrivateRoute exact path="/" component={Dashboard} />

            <PrivateRoute exact path="/configuracoes" component={ConfigurationFormEdit} />

            <PrivateRoute exact path="/lojas/form/:id" component={StoreForm} />
            <PrivateRoute exact path="/lojas/form" component={StoreForm} />
            <PrivateRoute exact path="/lojas" component={Store} />

            <PrivateRoute exact path="/reservas/conferencia/:id" component={OrderFormConference} />
            <PrivateRoute exact path="/reservas/importar" component={OrderImport} />
            <PrivateRoute exact path="/reservas/edit/:id" component={OrderFormEdit} />
            <PrivateRoute exact path="/reservas/nfe" component={OrderToInvoiceCreate} />
            <PrivateRoute exact path="/reservas" component={Order} />

            <PrivateRoute exact path="/transportadoras/editar/:id" component={ShippingCompanyFormEdit} />
            <PrivateRoute exact path="/transportadoras" component={ShippingCompany} />

            <PrivateRoute exact path="/notas-fiscais" component={Invoice} />

            <PrivateRoute exact path="/produtos/editar/:id" component={ProductFormEdit} />
            <PrivateRoute exact path="/produtos/novo" component={ProductFormNew} />
            <PrivateRoute exact path="/produtos" component={Product} />

            <PrivateRoute
                exact
                path="/emails/editar/:id"
                component={EmailTextFormEdit}
            />
            <PrivateRoute exact path="/emails" component={EmailText} />

            <PrivateRoute
                exact
                path="/requisitantes/editar/:id"
                component={RequesterFormEdit}
            />
            <PrivateRoute
                exact
                path="/requisitantes/novo"
                component={RequesterFormNew}
            />
            <PrivateRoute exact path="/requisitantes" component={Requester} />
            <PrivateRoute
                exact
                path="/requisitantes/sincronizar"
                component={RequesterSync}
            />

            <PrivateRoute exact path="/usergroups" component={UserGroup} />
            <PrivateRoute exact path="/usergroups/form" component={UserGroupForm} />
            <PrivateRoute exact path="/usergroups/form/:id" component={UserGroupForm} />

            <PrivateRoute exact path="/users" component={User} />
            <PrivateRoute exact path="/users/form" component={UserForm} />
            <PrivateRoute exact path="/users/form/:id" component={UserForm} />

        </Switch>
    </BrowserRouter>
);
export default Routes;
