import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { customStyles, paginationOptions } from "../../Shared/config";
import { Link } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";

import App from "../../Container/app";
import api from "../../Services/api";

import InvoiceItem from "./invoiceItem";

export default function Invoice(props) {
    const [loading, setLoading] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [fieldSearch, setFieldSearch] = useState("reference");
    const [invoices, setInvoices] = useState([]);
    const [invoiceId, setInvoiceId] = useState();

    const [extraFields, setExtraFields] = useState({
        Status: 0,
        DateInitialCreate: null,
        DateFinishCreate: null,
        DateInitialTransmit: null,
        DateFinishTransmit: null,
        ShippingCompanyId: null
    });

    const [sefaz, setSefaz] = useState({
        success: true,
        message: "",
        data: []
    });

    const [shippingCompany, setShippingCompany] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setTransmit(false);
        setShow(false);
    }

    const handleShow = (id) => {
        setSefaz({ codigo: "", mensagem: "" });
        setShow(true);
        setInvoiceId(id);
    }

    const [transmit, setTransmit] = useState(false);

    useEffect(() => {
        let ignore = false;
        const carriers = async () => {
            const response = await api.get(`/v1/shippingcompanies/all`);
            if (!ignore) setShippingCompany(response.data.data);
        };
        carriers();
        return () => {
            ignore = true;
        };
    }, []);

    const handleGetData = async () => {
        setLoading(true);
        var condition = `?like=true&${fieldSearch}=${textSearch}`;

        if (extraFields.ShippingCompanyId != null) {
            condition += "&shippingCompanyId=" + extraFields.ShippingCompanyId;
        }

        condition += "&status=" + extraFields.Status;

        if (extraFields.DateInitialCreate != null && extraFields.DateFinishCreate != null) {
            condition += "&DateInitialCreate=" + extraFields.DateInitialCreate;
            condition += "&DateFinishCreate=" + extraFields.DateFinishCreate;
        }
        if (extraFields.DateInitialTransmit != null && extraFields.DateFinishTransmit != null) {
            condition += "&DateInitialTransmit=" + extraFields.DateInitialTransmit;
            condition += "&DateFinishTransmit=" + extraFields.DateFinishTransmit;
        }

        const response = await api.get(`/v1/invoices/all${condition.toLowerCase()}`);
        const data = response.data;

        setInvoices(data);
        setLoading(false);
    };

    const handleTransmit = async () => {
        setTransmit(true);
        const response = await api.post(`/v1/invoices/transmit/` + invoiceId);
        const data = response.data;
        setSefaz(data);
        setTransmit(false);
    }

    const BtnDanfe = (props) => (
        props.id &&
        <Link to={{ pathname: "https://api.focusnfe.com.br" + props.id }} target="_blank">
            <i className="fas fa-file-alt"></i> Danfe</Link>
    );

    const BtnXML = (props) => (
        props.id &&
        <a href={"https://api.focusnfe.com.br" + props.id} download>
            <i className="fas fa-file-download"></i> Xml</a>
    );

    const BtnTransmit = (props) => (
        props.id &&
        <a href="#" onClick={() => { handleShow(props.id); }}><i className="fas fa-exchange-alt"></i> Transmitir</a>
    );

    const handleChangeTextSearch = (e) => {
        e.persist();
        setTextSearch(e.target.value);
    };

    const handleChangeFieldSearch = (e) => {
        e.persist();
        setFieldSearch(e.target.value);
    };

    const handleOnChange = (e) => {
        e.persist();
        setExtraFields({ ...extraFields, [e.target.name]: e.target.value });
    };

    const columns = [
        {
            name: "Referência",
            selector: "id",
            sortable: true,
            grow: 1,
        },
        {
            name: "Loja/Agência",
            selector: "store.initial",
            sortable: true,
            grow: 0,
        },
        {
            name: "Gerada em",
            selector: "invoiceDate",
            sortable: false,
        },
        {
            name: "Valor total",
            selector: row => row.totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            grow: 1,
        },
        {
            name: "Reservas",
            selector: "bookingIds",
            sortable: true,
            grow: 0,
        },
        {
            name: "Transportadora",
            selector: "shippingCompany.name",
            sortable: true,
        },
        {
            name: "Mensagem SEFAZ",
            selector: "messageSefaz",
            sortable: true,
        },
        {
            name: "Numero",
            selector: "invoiceNumber",
            sortable: true,
            grow: 0,
        },
        {
            name: "Transmitir",
            button: true,
            cell: (row) => <BtnTransmit id={row.id}></BtnTransmit>,
            grow: 0,
        },
        {
            name: "DANFE",
            button: true,
            cell: (row) => row.pathDanfe && <BtnDanfe id={row.pathDanfe} ></BtnDanfe>,
            grow: 0,
        },
        {
            name: "XML",
            button: true,
            cell: (row) => row.pathXML && <BtnXML id={row.pathXML}></BtnXML>,
            grow: 0,
        },
    ];

    return (
        <App>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Transmitir nota fiscal - SEFAZ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {transmit &&
                        <div className="d-flex align-items-center">
                            <strong>Transmitindo os dados...</strong>
                            <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    }

                    {sefaz.message &&
                        <div>
                            <p>
                                <Alert variant="danger">
                                    {sefaz.message}
                                </Alert>
                            </p>
                        </div>
                    }

                    {sefaz.data &&
                        <div>
                            {sefaz?.data?.map((erro) => (
                                <p>
                                    <Alert variant="info">
                                        {erro.mensagem}
                                    </Alert>
                                </p>
                            ))}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={() => { handleTransmit(); }}>Transmitir</Button>
                </Modal.Footer>
            </Modal>

            <div className="card spur-card">
                <div className="card-header bg-success text-white">
                    <div className="spur-card-title">
                        <i className="fas fa-layer-group"></i>&nbsp;Notas fiscais
                    </div>
                </div>
                <div className="card-body ">
                    <form className="form">
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label>Campo:</label>
                                <select
                                    className="form-control"
                                    id="field"
                                    name="field"
                                    onChange={handleChangeFieldSearch}
                                >
                                    <option value="reference">Referência</option>
                                    <option value="invoiceNumber">Número NF</option>
                                    <option value="store">Loja/Agência</option>
                                    <option value="bookingId">Reserva</option>
                                </select>
                            </div>
                            <div className="form-group col-md-10">
                                <label>Texto:</label>
                                <div className="input-group">
                                    <input
                                        id="textSearch"
                                        type="text"
                                        className="form-control"
                                        onChange={handleChangeTextSearch}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            onClick={handleGetData}
                                            className="btn btn-success"
                                            type="button"
                                        >
                                            <i className="fas fa-search"></i> Pesquisar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label>Gerada em</label>
                                <input
                                    id="DateInitialCreate"
                                    name="DateInitialCreate"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-2">
                                <label>até:</label>
                                <input
                                    id="DateFinishCreate"
                                    name="DateFinishCreate"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-1">
                                <label>Transmitida</label>
                                <select
                                    className="form-control"
                                    id="Status"
                                    name="Status"
                                    onChange={handleOnChange}
                                >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                    <option value="2">Todas</option>
                                </select>
                            </div>
                            <div className="form-group col-md-2">
                                <label>em</label>
                                <input
                                    id="DateInitialTransmit"
                                    name="DateInitialTransmit"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-2">
                                <label>até:</label>
                                <input
                                    id="DateFinishTransmit"
                                    name="DateFinishTransmit"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label>Transportadora:</label>
                                <select
                                    className="form-control"
                                    id="ShippingCompanyId"
                                    name="ShippingCompanyId"
                                    onChange={handleOnChange}
                                >
                                    <option key="0" value="">Todas</option>
                                    {shippingCompany.map((i) => (
                                        <option key={i.id} value={i.id}>
                                            {i.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {invoices.message !== "" && invoices.message !== undefined &&
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <div className="alert alert-success" role="alert">
                                        {invoices.message}
                                    </div>
                                </div>
                            </div>}

                        {loading &&
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <div className="alert alert-success" role="alert">
                                        Aguarde...
                                    </div>
                                </div>
                            </div>}

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <DataTable
                                    columns={columns}
                                    data={invoices.data}
                                    noDataComponent={""}
                                    noHeader
                                    defaultSortField="id"
                                    customStyles={customStyles}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    expandableRows
                                    expandableRowsComponent={<InvoiceItem />}
                                    expandOnRowClicked
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    dense
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </App>
    );
}
