import React, { useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import DataTable from "react-data-table-component";
import { customStyles, paginationOptions } from "../../Shared/config";
import { format } from "date-fns";

import App from "../../Container/app";
import api from "../../Services/api";

import { parseDateExcel, StringToCurrency } from "../../Shared/utils";

export default function OrderImport() {
  const [processing, setProcessing] = useState(false);
  const [plan, setPlan] = useState([]);
  const [resultPost, setResultPost] = useState({
    success: false,
    message: "",
    data: [],
  });

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const jsonString = JSON.stringify(arrayToJSONObject(resp.rows));
        const stringJson = JSON.parse(jsonString);
        setPlan(stringJson);
      }
    });
  };

  function arrayToJSONObject(arr) {
    var keys = [
      "Id",
      "ShipDate",
      "Departament",
      "Agency",
      "Store",
      "RequesterId",
      "SequencyId",
      "ProductId",
      "ProductDescription",
      "Quantity",
      "Unity",
      "Price",
    ];
    var newArr = arr.slice(1, arr.length);
    var formatted = [],
      data = newArr,
      cols = keys,
      l = cols.length;
    for (var i = 0; i < data.length; i++) {
      var d = data[i],
        o = {};
      for (var j = 0; j < l; j++) o[cols[j]] = d[j];
      formatted.push(o);
    }
    return formatted;
  }

  const columns = [
    {
      name: "Reserva",
      selector: "Id",
      sortable: true,
      grow: 0,
    },
    {
      name: "Data",
      selector: "ShipDate",
      sortable: false,
      cell: (row) => row.ShipDate
        ? format(new Date(parseDateExcel(row.ShipDate)), "dd/MM/yyyy")
        : "",
    },
    {
      name: "Departamento",
      selector: "Departament",
      sortable: false,
    },
    {
      name: "Agência",
      selector: "Agency",
      sortable: false,
    },
    {
      name: "Loja",
      selector: "Store",
      sortable: false,
    },
    {
      name: "Requisitante",
      selector: "RequesterId",
      sortable: false,
    },
    {
      name: "Item",
      selector: "SequencyId",
      sortable: false,
    },
    {
      name: "Produto",
      selector: "ProductId",
      sortable: false,
    },
    {
      name: "Produto Descrição",
      selector: "ProductDescription",
      sortable: false,
    },
    {
      name: "Quantidade",
      selector: "Quantity",
      sortable: false,
    },
    {
      name: "Unidade",
      selector: "Unity",
      sortable: false,
    },
    {
      name: "Preço",
      selector: "Price",
      sortable: false,
      right: true,
      cell: (row) => StringToCurrency(row.Price, "decimal"),
    },
  ];

  const handlePostOrdersAndItens = (e) => {
    e.preventDefault();
    try {
      setProcessing(true);
      var OrderAndItens = [];
      plan.forEach((element) => {
        OrderAndItens.push({
          Id: element.Id,
          ShipDate: element.ShipDate
            ? format(new Date(parseDateExcel(element.ShipDate)), "dd/MM/yyyy")
            : "",
          Departament: element.hasOwnProperty("Departament")
            ? element.Departament
            : "",
          Agency: element.hasOwnProperty("Agency") ? element.Agency : "",
          Store: element.hasOwnProperty("Store") ? element.Store : "",
          RequesterId: element.hasOwnProperty("RequesterId")
            ? element.RequesterId
            : 0,
          SequencyId: element.hasOwnProperty("SequencyId")
            ? element.SequencyId
            : 0,
          ProductId: element.hasOwnProperty("ProductId")
            ? element.ProductId
            : "",
          ProductDescription: element.hasOwnProperty("ProductDescription")
            ? element.ProductDescription
            : "",
          Quantity: element.hasOwnProperty("Quantity") ? element.Quantity : 0,
          Unity: element.hasOwnProperty("Unity") ? element.Unity : "",
          Price: element.hasOwnProperty("Price") ? element.Price : 0,
        });
      });

      const data = {
        CreatedByUserId: 1,
        Orders: OrderAndItens,
      };
      api.post("/v1/orders/import", data).then((result) => {
        setResultPost({
          success: result.data.success,
          message: result.data.message,
          data: result.data.data,
        });
      });
      setProcessing(false);
    } catch (error) {
      setResultPost(false, e.message, null);
      setProcessing(false);
    }
  };

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="far fa-clipboard"></i>&nbsp;Importar reservas SAP
          </div>
        </div>
        <div className="card-body ">
          <form className="form">
            <div className="form-row">
              <div className="form-group col-md-2">
                <div className="">
                  <input
                    type="file"
                    id="input-file"
                    name="input-file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={fileHandler.bind(this)}
                    hidden
                  />
                  <label
                    className="btn btn-success"
                    htmlFor="input-file"
                    role="button"
                  >
                    Escolher arquivo
                  </label>
                </div>
              </div>
              <div className="form-group col-md-10 text-right">
                <button
                  onClick={handlePostOrdersAndItens}
                  id="btnSend"
                  className="btn btn-success"
                  disabled={processing}
                >
                  {processing ? " Processando..." : " Enviar"}
                </button>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={plan}
              noHeader
              noDataComponent={""}
              defaultSortField="name"
              customStyles={customStyles}
              pagination
              paginationComponentOptions={paginationOptions}
              highlightOnHover
              striped
              pointerOnHover
              dense
            />
          </form>

          <div className="col text-danger text-right">
            {resultPost.message}
            {resultPost.success
              ? ""
              : resultPost?.data?.map((item, i) => (
                <div key={i}>{item.message}</div>
              ))}
          </div>

          <div className="alert alert-warning" role="alert">
            Layout obrigatório da planilha do Excell
          </div>
          <table className="table table-sm">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Coluna</th>
                <th scope="col">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Reserva (A)</th>
                <td>Número da reserva</td>
              </tr>
              <tr>
                <th scope="row">DataEmissão (B)</th>
                <td>Data da emissão da reserva</td>
              </tr>
              <tr>
                <th scope="row">Departamento (C)</th>
                <td>Departamento emitente da reserva</td>
              </tr>
              <tr>
                <th scope="row">Agência (D)</th>
                <td>Sigla da agência </td>
              </tr>
              <tr>
                <th scope="row">Loja (E)</th>
                <td>Sigla da loja </td>
              </tr>
              <tr>
                <th scope="row">Usuario (F)</th>
                <td>Usuário requisitante responsavél pela reserva</td>
              </tr>
              <tr>
                <th scope="row">Item (G)</th>
                <td>Sequêncial de produtos para cada reserva</td>
              </tr>
              <tr>
                <th scope="row">Código Produto (H)</th>
                <td>Código do produto/material no SAP</td>
              </tr>
              <tr>
                <th scope="row">Descrição Produto (I)</th>
                <td>Descrição do produto/material no SAP</td>
              </tr>
              <tr>
                <th scope="row">Quantidade (J)</th>
                <td>Quantidade requisitada, não requer formatação</td>
              </tr>
              <tr>
                <th scope="row">Unidade (k)</th>
                <td>Unidade de medida</td>
              </tr>
              <tr>
                <th scope="row">Preço (L)</th>
                <td>Preço unitário, não requer formatação</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </App>
  );
}
