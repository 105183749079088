import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { useTable } from 'react-table'
import { customStyles, paginationOptions } from "../../Shared/config";
import DataTable from "react-data-table-component";
import api from "../../Services/api";
import App from "../../Container/app";
import BTable from 'react-bootstrap/Table';

function Table({ columns, data, status }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    status
  })
  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead className="thead-dark">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}><b>{column.render('Footer')}</b></td>
            ))}
          </tr>
        ))}
      </tfoot>
    </BTable>
  )
}

export default function OrderFormConference(props) {
  const [order, setOrder] = useState({
    id: "",
    bookingId: "",
    departament: "",
    agencyId: "",
    storeId: "",
    requesterId: "",
    status: "",
    statusDescription: "",
    shippingBy: "",
    shippingByDescription: "",
    shipDate: "",
    invoiceId: "",
    invoiceNumber: "",
    postOfficesNumber: "",
    transportNumber: "",
    orderItems: [],
  });

  const [orderItems, setOrderItems] = React.useState([])

  const [shippingData, setShippingData] = useState({
    id: "",
    shippingBy: "",
    postOfficesNumber: null,
    transportNumber: null,
    invoiceId: null,
    invoiceNumber: null,
    withInvoice: null
  });

  const [postOffice, setPostOffice] = useState({
    success: false,
    message: "",
    data: [],
  });
  const [showTrackingsPostOffice, setShowTrackingsPostOffice] = useState(false);

  const STATUS_CONFERENCE = 1;
  const STATUS_WAITING_FOR_INVOCE = 3;
  const STATUS_IN_TRANSIT = 4;

  const SHIPPING_POST_OFFICES = 1;
  const SHIPPING_SHIPPING_COMPANY = 2;
  const SHIPPING_POUCH = 3;
  const SHIPPING_COLLECT = 4;
  const SHIPPING_MATRIZ = 5;

  const [show, setShow] = useState(false);

  const status = [
    "Importada do SAP",
    "Em conferência (separação)",
    "Liberado para envio",
    "Aguardando emissão nota fiscal",
    "Em trânsito",
    "Entregue",
  ];

  const shipping = [
    "Em processamento",
    "Correios",
    "Transportadora",
    "Malote",
    "Coleta",
    "Matriz"
  ];

  const [resultPost, setResultPost] = useState({
    success: true,
    message: "",
    data: [],
  });

  const url = `/v1/orders/${props.match.params.id}`;
  useEffect(() => {
    const GetData = async () => {
      const response = await api.get(url);
      setOrder(response.data.data);
      setOrderItems(response.data.data.orderItems);
    };
    GetData();
  }, [url]);

  const handlerUpdateStatus = async (status) => {
    const data = {
      Id: parseInt(props.match.params.id),
      Status: status,
    };
    const result = await api.put("/v1/orders/status", data);
    setResultPost(result.data);
    if (result.data.success) {
      setOrder({ ...order, status: status });
    }
  }

  const handlerUpdateShippingBy = async () => {
    const data = {
      Id: parseInt(props.match.params.id, 10),
      ShippingBy: shippingBy,
      PostOfficesNumber: shippingData.postOfficesNumber,
      TransportNumber: shippingData.transportNumber,
      Invoice: shippingData.invoice,
      InvoiceSerie: shippingData.invoiceSerie,
      InvoiceCreate: checked,
      CreatedByUserId: 1,
      OrdersItems: orderItems
    };
    const result = await api.put("/v1/orders/shipping", data);
    setResultPost(result.data);
    if (resultPost.success) {
      const newStatus =
        shipping === 2 ? STATUS_WAITING_FOR_INVOCE : STATUS_IN_TRANSIT;
      setOrder({ ...order, status: newStatus });
    }
    handleClose();
  };

  const handlerSave = async () => {
    const data = {
      OrdersItems: orderItems
    };
    var result = await api.put("/v1/orders/checking-out", data);
    setResultPost({
      success: result.data.success,
      message: result.data.message,
      data: result.data.data,
    });
  };

  const [shippingBy, setShippingBy] = useState(0);
  const handleChoiceShippingBy = (value) => {
    setShippingBy(value)
  };

  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="checkbox" checked={value} onChange={onChange} />
        {' ' + label}
      </label>
    );
  };

  const handleClose = () => setShow(false);

  const getTrackingsPostOffices = async (e) => {
    e.preventDefault();
    const url = `/v1/orders/post-offices/${order.postOfficesNumber}`;
    const response = await api.get(url);
    setPostOffice(response.data);
    setShowTrackingsPostOffice(true);
  };

  const ChangeQuantityServed = ({
    value: initialValue,
    row: { index },
    column: { id },
    status
  }) => {
    const [value, setValue] = React.useState(initialValue)
    const onChange = e => {
      setValue(e.target.value)
    }
    const onBlur = () => {
      setOrderItems(old =>
        old.map((row, idx) => {
          if (idx === index) {
            return {
              ...old[index],
              [id]: value,
            }
          }
          return row
        })
      )
    }
    return status === 1
      ? <input value={value} onChange={onChange} onBlur={onBlur} />
      : value
  }

  const columnsPostOffice = [
    {
      name: "Data",
      selector: "date",
      sortable: false,
    },
    {
      name: "Localização",
      selector: "location",
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
  ];

  const columnsConference = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'sequencyId',
        Footer: '',
      },
      {
        Header: 'Código SAP',
        accessor: 'productId',
        Footer: '',
      },
      {
        Header: 'Produto',
        accessor: 'productName',
        Footer: '',
      },
      {
        Header: 'Quantidade solicitada',
        accessor: 'quantity',
        Footer: '',
      },
      {
        Header: 'Quantidade atendida',
        accessor: 'quantityServed',
        Footer: '',
        Cell: ChangeQuantityServed
      },
      {
        Header: 'Unidade',
        accessor: 'unity',
        Footer: '',
      },
      {
        Header: 'R$ unitário',
        accessor: row => row.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        Footer: 'Total:',
      },
      {
        Header: 'R$ total',
        accessor: row => (row.price * row.quantityServed)
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        Footer: info => {
          const total = React.useMemo(() =>
            info.rows.reduce((sum, row) => (row.original.price * row.original.quantityServed) + sum, 0),
            [info.rows]
          )
          return <>{total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</>
        },
      },
    ],
    []
  )

  return (
    <App>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Envio</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Form>
              <Row className="align-items-center">
                <Col sm={12} className="my-1">
                  <Form.Group className="">
                    <Form.Check
                      inline
                      label="Correios"
                      name="group1"
                      type="radio"
                      id={SHIPPING_POST_OFFICES}
                      onChange={e => handleChoiceShippingBy(SHIPPING_POST_OFFICES)}
                    />
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Check
                      inline
                      label="Transportadora"
                      name="group1"
                      type="radio"
                      id={SHIPPING_SHIPPING_COMPANY}
                      onChange={e => handleChoiceShippingBy(SHIPPING_SHIPPING_COMPANY)}
                    />
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Check
                      inline
                      label="Malote"
                      name="group1"
                      type="radio"
                      id={SHIPPING_POUCH}
                      onChange={e => handleChoiceShippingBy(SHIPPING_POUCH)}
                    />
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Check
                      inline
                      label="Coleta"
                      name="group1"
                      type="radio"
                      id={SHIPPING_COLLECT}
                      onChange={e => handleChoiceShippingBy(SHIPPING_COLLECT)}
                    />
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Check
                      inline
                      label="Matriz"
                      name="group1"
                      type="radio"
                      id={SHIPPING_MATRIZ}
                      onChange={e => handleChoiceShippingBy(SHIPPING_MATRIZ)}
                    />
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Control
                      placeholder="Informe o número rastreamento correios"
                      id="postOfficesNumber"
                      name="postOfficesNumber"
                      onChange={(e) =>
                        setShippingData({ ...shippingData, postOfficesNumber: e.target.value })
                      } />
                  </Form.Group>

                  <Form.Group className="">
                    <Checkbox
                      label="Gerar nota fiscal"
                      value={checked}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { handlerUpdateShippingBy(); }}>
            Gravar
          </Button>
          <Button variant="success" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="far fa-clipboard"></i>&nbsp;Reserva
          </div>
        </div>
        <div className="card-body ">
          <form>
            <div className="form-row">
              <div className="form-group col-md-1">
                <label>Id :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="id"
                  defaultValue={order.id}
                />
              </div>
              <div className="form-group col-md-1">
                <label>Reserva :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="id"
                  defaultValue={order.bookingId}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Data :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="shipDate"
                  name="shipDate"
                  defaultValue={order.shipDate}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Departamento :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="departament"
                  name="departament"
                  defaultValue={order.departament}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Agência :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="AgencyId"
                  name="AgencyId"
                  defaultValue={order.agencyId}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Loja :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="StoreId"
                  name="StoreId"
                  defaultValue={order.storeId}
                />
              </div>
              <div className="form-group col-md-2 text-center">
                <label className="text-danger">
                  <span className="badge badge-success">
                    {status[order.status]}
                  </span>
                  <br />
                  <span className="badge badge-success">
                    {shipping[order.shippingBy]}
                  </span>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>Requerido por :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="RequesterName"
                  name="RequesterName"
                  defaultValue={order.requesterName}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Número da NFe :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="invoiceNumber"
                  name="invoiceNumber"
                  defaultValue={order.invoiceNumber}
                />
              </div>
              <div className="form-group col-md-4">
                <label>Rastreio transportadora :</label>
                <div className="input-group mb-3">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="transportNumber"
                    name="transportNumber"
                    defaultValue={order.transportNumber}
                  />
                </div>
              </div>
              <div className="form-group col-md-2">
                <label>Rastreio correio:</label>
                <div className="input-group mb-3">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="postOfficesNumber"
                    name="postOfficesNumber"
                    defaultValue={order.postOfficesNumber}
                  />
                  {order.postOfficesNumber && (
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={getTrackingsPostOffices}
                      >
                        <i className="fas fa-location-arrow"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showTrackingsPostOffice ? (
              <div className="form-row">
                <div className="form-group col-12">
                  <DataTable
                    columns={columnsPostOffice}
                    data={postOffice.data}
                    noHeader
                    noDataComponent={""}
                    defaultSortField="name"
                    customStyles={customStyles}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    highlightOnHover
                    striped
                    pointerOnHover
                    dense
                  />
                </div>
                <div className="form-group col-12 text-right">
                  <button
                    onClick={() => setShowTrackingsPostOffice(false)}
                    className="btn btn-primary btn-sm"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <div className="row">
                <div className="col-5">
                  <Link to="/reservas">
                    <button type="submit" className="btn btn-success">
                      <i className="fas fa-arrow-left"></i> Voltar
                    </button>
                  </Link>
                  {order.status === 0 && (
                    <button
                      onClick={() => {
                        handlerUpdateStatus(STATUS_CONFERENCE);
                      }}
                      type="button"
                      className="btn btn-success"
                    >
                      <i className="fas fa-check-double"></i> Conferência
                      (separação)
                    </button>
                  )}
                </div>
                <div className="col-7"></div>
              </div>
              <div className="clearfix">&nbsp;</div>
              <div className="form-row">
                <div className="col-md-12">
                  <Table columns={columnsConference} data={orderItems} status={order.status} />
                </div>
              </div>
              <div className="row">
                <div className="col-8 text-danger">
                  {resultPost.success
                    ? resultPost.message
                    : resultPost.data.map((item, i) => (
                      <div key={i}>{item.message}</div>
                    ))
                  }
                </div>
                <div className="col-4 text-right">
                  {order.status === 1 &&
                    <div>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handlerSave}
                      >
                        <i className="fas fa-save"></i> Gravar
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setShow(true)}
                      >
                        <i className="fas fa-cart-arrow-down"></i> Finalizar
                        conferência
                      </button>
                    </div>}
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </App >
  );
}
