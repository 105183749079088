function RemoveCharactersCNPJCPF(value) {
  const newStr = value
    .normalize("NFD")
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
  return newStr;
}

const parseDateExcel = (excelTimestamp) => {
  const secondsInDay = 24 * 60 * 60;
  const excelEpoch = new Date(1899, 11, 31);
  const excelEpochAsUnixTimestamp = excelEpoch.getTime();
  const missingLeapYearDay = secondsInDay * 1000;
  const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
  const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
  const parsed = excelTimestampAsUnixTimestamp + delta;
  return isNaN(parsed) ? null : new Date(parsed).toISOString();
};

function StringToCurrency(value, type) {
  const amount = new Intl.NumberFormat("pt-BR", {
    style: `${type}`,
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);
  return `${amount}`;
}

function currencyFormatter(value) {
  if (!Number(value)) return "";
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);

  return `${amount}`;
}

export { RemoveCharactersCNPJCPF, parseDateExcel, StringToCurrency, currencyFormatter };
