import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTable } from 'react-table';
import api from "../../Services/api";
import App from "../../Container/app";
import BTable from 'react-bootstrap/Table';

function Table({ columns, data, status }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    status
  })
  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead className="thead-dark">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}><b>{column.render('Footer')}</b></td>
            ))}
          </tr>
        ))}
      </tfoot>
    </BTable>
  )
}

export default function OrderFormEdit(props) {
  const [order, setOrder] = useState({
    id: "",
    bookingId: "",
    departament: "",
    agencyId: "",
    storeId: "",
    requesterId: "",
    status: "",
    statusDescription: "",
    shippingBy: "",
    shippingByDescription: "",
    shipDate: "",
    invoiceId: "",
    invoiceNumber: "",
    postOfficesNumber: "",
    transportNumber: "",
    orderItems: [],
  });

  const status = [
    "Importada do SAP",
    "Em conferência (separação)",
    "Liberado para envio",
    "Aguardando emissão nota fiscal",
    "Em trânsito",
    "Entregue",
  ];

  const [orderItems, setOrderItems] = useState([])

  const [resultPost, setResultPost] = useState({
    success: true,
    message: "",
    data: [],
  });

  const url = `/v1/orders/${props.match.params.id}`;
  useEffect(() => {
    const GetData = async () => {
      const response = await api.get(url);
      setOrder(response.data.data);
      setOrderItems(response.data.data.orderItems);
    };
    GetData();
  }, [url]);

  const handlerSave = async () => {
    const data = {
      OrdersItems: orderItems
    };
    var result = await api.put("/v1/orders/checking-out", data);
    setResultPost({
      success: result.data.success,
      message: result.data.message,
      data: result.data.data,
    });
  };

  const ChangeQuantityServed = ({
    value: initialValue,
    row: { index },
    column: { id },
    status
  }) => {
    const [value, setValue] = React.useState(initialValue)
    const onChange = e => {
      setValue(e.target.value)
    }
    const onBlur = () => {
      setOrderItems(old =>
        old.map((row, idx) => {
          if (idx === index) {
            return {
              ...old[index],
              [id]: value,
            }
          }
          return row
        })
      )
    }
    return status <= 1
      ? <input value={value} onChange={onChange} onBlur={onBlur} />
      : value
  }

  const columnsConference = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: 'sequencyId',
        Footer: '',
      },
      {
        Header: 'Código SAP',
        accessor: 'productId',
        Footer: '',
      },
      {
        Header: 'Produto',
        accessor: 'productName',
        Footer: '',
      },
      {
        Header: 'Quantidade solicitada',
        accessor: 'quantity',
        Footer: '',
        Cell: ChangeQuantityServed
      },
      {
        Header: 'Quantidade atendida',
        accessor: 'quantityServed',
        Footer: '',
      },
      {
        Header: 'Unidade',
        accessor: 'unity',
        Footer: '',
      },
      {
        Header: 'R$ unitário',
        accessor: row => row.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        Footer: 'Total:',
      },
      {
        Header: 'R$ total',
        accessor: row => (row.price * row.quantityServed)
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        Footer: info => {
          const total = React.useMemo(() =>
            info.rows.reduce((sum, row) => (row.original.price * row.original.quantityServed) + sum, 0),
            [info.rows]
          )
          return <>{total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</>
        },
      },
    ],
    []
  )

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="far fa-clipboard"></i>&nbsp;Editando reserva
          </div>
        </div>
        <div className="card-body ">
          <form>
            <div className="form-row">
              <div className="form-group col-md-1">
                <label>Id :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="id"
                  defaultValue={order.id}
                />
              </div>
              <div className="form-group col-md-1">
                <label>Reserva :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="id"
                  defaultValue={order.bookingId}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Data :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="shipDate"
                  name="shipDate"
                  defaultValue={order.shipDate}
                />
              </div>
              <div className="form-group col-md-1">
                <label>Departamento :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="departament"
                  name="departament"
                  defaultValue={order.departament}
                />
              </div>
              <div className="form-group col-md-1">
                <label>Agência :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="agencyId"
                  name="agencyId"
                  defaultValue={order.agencyId}
                />
              </div>
              <div className="form-group col-md-1">
                <label>Loja :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="storeId"
                  name="storeId"
                  defaultValue={order.storeId}
                />
              </div>
              <div className="form-group col-md-3">
                <label>Requerido por :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="requesterName"
                  name="requesterName"
                  defaultValue={order.requesterName}
                />
              </div>
              <div className="form-group col-md-2 text-center">
                <label className="text-danger">
                  <span className="badge badge-danger">
                    {status[order.status]}
                  </span>
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <Table columns={columnsConference} data={orderItems} status={order.status} />
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <Link to="/reservas">
                    <button type="submit" className="btn btn-success">
                      <i className="fas fa-arrow-left"></i> Voltar
                    </button>
                  </Link>
                </div>
                <div className="col-7 text-danger">
                  {resultPost.success
                    ? resultPost.message
                    : resultPost.data.map((item, i) => (
                      <div key={i}>{item.message}</div>
                    ))
                  }
                </div>
                <div className="col-4 text-right">
                  <div>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handlerSave}
                    >
                      <i className="fas fa-save"></i> Gravar
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </App>
  );
}
