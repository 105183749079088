import React, { useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";

import DataTable from "react-data-table-component";
import { customStyles, paginationOptions } from "../../Shared/config";

import App from "../../Container/app";
import api from "../../Services/api";

export default function RequesterSync() {
  const [processing, setProcessing] = useState(false);
  const [plan, setPlan] = useState([]);
  const [resultPost, setResultPost] = useState({
    success: false,
    message: "",
    data: [],
  });

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const jsonString = JSON.stringify(arrayToJSONObject(resp.rows));
        const stringJson = JSON.parse(jsonString);
        setPlan(stringJson);
      }
    });
  };

  function arrayToJSONObject(arr) {
    var keys = ["id", "name", "email"];
    var newArr = arr.slice(1, arr.length);
    var formatted = [],
      data = newArr,
      cols = keys,
      l = cols.length;
    for (var i = 0; i < data.length; i++) {
      var d = data[i],
        o = {};
      for (var j = 0; j < l; j++) o[cols[j]] = d[j];
      formatted.push(o);
    }
    return formatted;
  }

  const columns = [
    {
      name: "Matricula",
      selector: "id",
      sortable: true,
      grow: 0,
    },
    {
      name: "Nome",
      selector: "name",
      sortable: false,
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
  ];

  const handlePostRequesters = async (e) => {
    e.preventDefault();
    setProcessing(true);
    var requesters = [];
    plan.forEach((element) => {
      requesters.push({
        id: element.id,
        name: element.hasOwnProperty("name") ? element.name : "",
        email: element.hasOwnProperty("email") ? element.email : "",
      });
    });
    const data = { CreatedByUserId: 1, SyncRequesters: requesters };
    const result = await api.post("/v1/requesters/sync", data);
    setResultPost(result.data);
    setProcessing(false);
  };

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="fas fa-user-check"></i>&nbsp;Sincronizar cadastros
            requisitantes
          </div>
        </div>
        <div className="card-body ">
          <form className="form">
            <div className="form-row">
              <div className="form-group col-md-2">
                <div className="">
                  <input
                    type="file"
                    id="input-file"
                    name="input-file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={fileHandler.bind(this)}
                    hidden
                  />
                  <label
                    className="btn btn-success"
                    htmlFor="input-file"
                    role="button"
                  >
                    Escolher arquivo
                  </label>
                </div>
              </div>
              <div className="form-group col-md-10 text-right">
                <button
                  disabled={processing}
                  onClick={handlePostRequesters}
                  id="btnSend"
                  className="btn btn-success"
                >
                  {processing ? " Processando..." : " Enviar"}
                </button>
              </div>
            </div>
            {processing && (
              <div className="alert alert-success" role="alert">
                Aguarde, sincronizando os dados...
              </div>
            )}

            <DataTable
              columns={columns}
              data={plan}
              noHeader
              noDataComponent={""}
              defaultSortField="name"
              customStyles={customStyles}
              pagination
              paginationComponentOptions={paginationOptions}
              highlightOnHover
              striped
              pointerOnHover
              dense
            />
          </form>

          <div className="col text-danger text-right">
            {resultPost.message}
          </div>

          <div className="alert alert-warning" role="alert">
            Layout obrigatório da planilha do Excell
          </div>
          <table className="table table-sm">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Coluna</th>
                <th scope="col">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Matricula (A)</th>
                <td>Matrícula do usuário requerente</td>
              </tr>
              <tr>
                <th scope="row">Nome (B)</th>
                <td>Nome do requerente</td>
              </tr>
              <tr>
                <th scope="row">Email (C)</th>
                <td>Email do requerente</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </App>
  );
}
