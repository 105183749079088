import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import api from "../../Services/api";
import { customStyles, paginationOptions } from "../../Shared/config";

export default function Tracking(props) {
  const [postOffice, setPostOffice] = useState({
    success: false,
    message: "",
    data: [],
  });

  const url = `/v1/orders/post-offices/${props.match.params.id}`;
  useEffect(() => {
    let ignore = false;
    const postOffices = async () => {
      const response = await api.get(url);
      if (!ignore) setPostOffice(response.data)
    };
    postOffices();
    return () => {
      ignore = true;
    };
  }, [url]);

  const columnsPostOffice = [
    {
      name: "Data",
      selector: "date",
      sortable: false,
    },
    {
      name: "Localização",
      selector: "location",
      sortable: false,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
  ];

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <DataTable
          columns={columnsPostOffice}
          data={postOffice.data}
          noHeader
          noDataComponent={""}
          defaultSortField="name"
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationOptions}
          highlightOnHover
          striped
          pointerOnHover
          dense
        />
      </div>
    </div>
  );
}
