import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../Services/api";
import App from "../../Container/app";

export default function EmailTextFormEdit(props) {
  const [emailtext, setEmailText] = useState({
    id: "",
    stage: "",
    title: "",
    textBody: "",
  });

  const [resultPost, setResultPost] = useState({
    success: true,
    message: "",
    data: [],
  });

  const handleUpdateEmailText = (e) => {
    e.preventDefault();
    setResultPost({ success: false, message: "", data: [] });
    const data = {
      Id: parseInt(props.match.params.id),
      stage: emailtext.stage,
      title: emailtext.title,
      textBody: emailtext.textBody,
    };
    api.put("/v1/emails", data).then((result) => {
      setResultPost({
        success: result.data.success,
        message: result.data.message,
        data: result.data.data,
      });
    });
  };

  const url = `/v1/emails/${props.match.params.id}`;
  useEffect(() => {
    const GetData = async () => {
      const response = await api.get(url);
      setEmailText(response.data.data);
    };
    GetData();
  }, [url]);

  const onChange = (e) => {
    e.persist();
    setEmailText({ ...emailtext, [e.target.name]: e.target.value });
  };

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="fas fa-at"></i>&nbsp;Texto do email
          </div>
        </div>
        <div className="card-body ">
          <form onSubmit={handleUpdateEmailText}>
            <div className="form-row">
              <div className="form-group col-mb-2">
                <label>Id :</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id="id"
                  name="id"
                  value={emailtext.id}
                />
              </div>
              <div className="form-group col-md-10">
                <label>Título:</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  value={emailtext.title}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12"></div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <button type="submit" className="btn btn-success">
                    <i className="fas fa-save"></i> Gravar
                  </button>
                  <Link to="/emails">
                    <button type="submit" className="btn btn-success">
                      <i className="fas fa-arrow-left"></i> Voltar
                    </button>
                  </Link>
                </div>
                <div className="col text-danger text-right">
                  {resultPost.message}
                  {resultPost.success
                    ? ""
                    : resultPost?.data?.map((item, i) => (
                      <div key={i}>{item.message}</div>
                    ))}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </App>
  );
}
