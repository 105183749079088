import React from "react";
import { Link, useHistory } from "react-router-dom";

export default function Content({ children }) {
  const history = useHistory();
  const handlerLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user_fca");
    history.push("/");
  };

  return (
    <div className="dash">
      <div className="dash-nav dash-nav-dark">
        <header>
          <span className="menu-toggle">
            <i className="fas fa-bars"></i>
          </span>
          <Link to="/" className="spur-logo">
            &nbsp;<span>FCA Express</span>
          </Link>
        </header>
        <nav className="dash-nav-list">
          <Link to="/" className="dash-nav-item">
            <i className="far fa-chart-bar"></i> Dashboard
          </Link>
          <Link to="/job" className="dash-nav-item">
            <i className="fas fa-cogs"></i>&nbsp;Status Job's
          </Link>
          <Link to="/lojas" className="dash-nav-item">
            <i className="fas fa-home"></i>&nbsp;Agências/lojas
          </Link>
          <Link to="/notas-fiscais" className="dash-nav-item">
            <i className="fas fa-layer-group"></i> Notas fiscais
          </Link>
          <Link to="/produtos" className="dash-nav-item">
            <i className="fas fa-gift"></i>&nbsp;Produtos
          </Link>
          <Link to="/reservas" className="dash-nav-item">
            <i className="far fa-clipboard"></i> Reservas
          </Link>
          <Link to="/requisitantes" className="dash-nav-item">
            <i className="fas fa-user-check"></i>&nbsp;Requisitantes
          </Link>
          <Link to="/transportadoras" className="dash-nav-item">
            <i className="fas fa-truck-moving"></i>&nbsp;Transportadoras
          </Link>
          <Link to="/relatorios/reservas" className="dash-nav-item">
            <i className="fas fa-clipboard-list"></i>&nbsp;Relatório
          </Link>
          <Link to="" onClick={handlerLogout} className="dash-nav-item">
            <i className="fas fa-door-open"></i>&nbsp;Sair do sistema
          </Link>
        </nav>
      </div>
      <div className="dash-app">
        <header className="dash-toolbar">
          <span className="menu-toggle">
            <i className="fas fa-bars"></i>
          </span>
          <div className="tools">
            <div className="dropdown tools-item">
              <span
                className="dash-nav-dropdown-item"
                id="dropdownMenuUser"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-cog"></i>
              </span>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuUser"
              >
                <Link to="/eu" className="dropdown-item">
                  Profile
                </Link>
                <Link to="/configuracoes" className="dropdown-item">
                  Configurações
                </Link>
                <Link to="/emails" className="dropdown-item">
                  Textos de emails
                </Link>
              </div>
            </div>
          </div>
        </header>
        <main className="dash-content">
          <div className="container-fluid">{children}</div>
        </main>
      </div>
    </div>
  );
}
