import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import api from "../../Services/api";
import App from "../../Container/app";
import { customStyles, paginationOptions } from "../../Shared/config";

export default function Order(props) {
    const [loading, setLoading] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [fieldSearch, setFieldSearch] = useState("bookingId");
    const [orders, setOrders] = useState([]);
    const [extraFields, setExtraFields] = useState({
        Status: 0,
        ShippingWay: 99,
        ShipDateInitial: "",
        ShipDateFinish: "",
        Complement: 0
    });

    const [hasInvoices, setHasInvoices] = useState("");

    const ALL = 99;

    const STATUS_IMPORTED_SAP = 0;
    const STATUS_CONFERENCE = 1;
    const STATUS_WAITING_FOR_INVOCE = 3;
    const STATUS_IN_TRANSIT = 4;
    const STATUS_DELIVERED = 5;

    const SHIPPING_IN_PROCESS = 0;
    const SHIPPING_POST_OFFICES = 1;
    const SHIPPING_SHIPPING_COMPANY = 2;
    const SHIPPING_POUCH = 3;
    const SHIPPING_COLLECT = 4;
    const SHIPPING_MATRIZ = 5;

    useEffect(() => {
        let ignore = false;
        const getInvoicesOpened = async () => {
            const response = await api.get(`/v1/orders/to-invoice`);
            if (!ignore)
                setHasInvoices(response.data.message);
        };
        getInvoicesOpened();
        return () => {
            ignore = true;
        };
    }, []);

    const handleGetData = async () => {
        var condition = `?like=true&${fieldSearch}=${textSearch}`;
        condition += "&shipping=" + extraFields.ShippingWay;
        condition += "&status=" + extraFields.Status;
        condition += "&complement=" + extraFields.Complement;
        if (
            extraFields.ShipDateInitial != null &&
            extraFields.ShipDateFinish != null
        ) {
            condition += "&ShipDateInitial=" + extraFields.ShipDateInitial;
            condition += "&ShipDateFinish=" + extraFields.ShipDateFinish;
        }
        setLoading(true);

        const response = await api.get(`/v1/orders/all${condition.toLowerCase()}`);
        const data = response.data;
        setOrders(data);
        setLoading(false);
    };

    const handleChangeTextSearch = (e) => {
        e.persist();
        setTextSearch(e.target.value);
    };

    const handleChangeFieldSearch = (e) => {
        e.persist();
        setFieldSearch(e.target.value);
    };

    const handleOnChange = (e) => {
        e.persist();
        setExtraFields({ ...extraFields, [e.target.name]: e.target.value });
    };

    const ButtonCheck = (Props) => (
        <button data-toggle="tooltip" data-placement="top" title="Separação para entrega"
            onClick={() => {
                handleConferenceOrder(Props.id);
            }}
            className="btn btn-sm btn-success"
        >
            <i className="fas fa-check-double"></i> Separar
        </button>
    );

    const ButtonEdit = (Props) => (
        <button data-toggle="tooltip" data-placement="top" title="Editar"
            onClick={() => {
                handleEditOrder(Props.id);
            }}
            className="btn btn-sm btn-success"
        >
            <i className="fas fa-edit"></i> Editar
        </button>
    );

    const columns = [
        {
            name: "Reserva",
            selector: "bookingId",
            sortable: true,
        },
        {
            name: "Complto",
            selector: "complementary",
            cell: (row) => row.complementary === 1 ? "Sim" : "",
        },
        {
            name: "Departamento",
            selector: "departament",
            sortable: true,
        },
        {
            name: "Agência",
            selector: "agencyId",
            sortable: true,
        },
        {
            name: "Loja",
            selector: "storeId",
            sortable: true,
        },
        {
            name: "Data reserva",
            selector: "shipDate",
            sortable: true,
            cell: (row) => row.shipDate.substring(0, 10),
        },
        {
            name: "Nota Fiscal",
            selector: "invoiceNumber",
            sortable: true,
        },
        {
            name: "Situação",
            selector: "statusDescription",
            sortable: true,
        },
        {
            name: "Envio",
            selector: "shippingByDescription",
            sortable: true,
        },
        {
            name: "Data Importação",
            selector: "createdDate",
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: (row) => (<ButtonEdit id={row.id}></ButtonEdit>),
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            name: "",
            button: true,
            cell: (row) => (<ButtonCheck id={row.id}></ButtonCheck>),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    const handleConferenceOrder = (id) => {
        props.history.push({
            pathname: "reservas/conferencia/" + id,
        });
    };

    const handleEditOrder = (id) => {
        props.history.push({
            pathname: "reservas/edit/" + id,
        });
    };

    return (
        <App>
            <div className="card spur-card">
                <div className="card-header bg-success text-white">
                    <div className="spur-card-title">
                        <i className="far fa-clipboard"></i>&nbsp;Reservas
                    </div>
                </div>
                <div className="card-body ">
                    <form className="form">
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label>Campo:</label>
                                <select
                                    className="form-control"
                                    id="field"
                                    name="field"
                                    onChange={handleChangeFieldSearch}
                                >
                                    <option value="bookingId">Reserva</option>
                                    <option value="agency">Agência</option>
                                    <option value="store">Loja</option>
                                    <option value="departament">Departamento</option>
                                    <option value="requesterId">Matricula</option>
                                    <option value="requesterName">Requisitante</option>
                                    <option value="invoiceNumber">Nota fiscal</option>
                                    <option value="id">Id</option>
                                </select>
                            </div>
                            <div className="form-group col-md-10">
                                <label>Texto:</label>
                                <div className="input-group">
                                    <input
                                        id="textSearch"
                                        type="text"
                                        className="form-control"
                                        onChange={handleChangeTextSearch}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            onClick={handleGetData}
                                            className="btn btn-success"
                                            type="button"
                                        >
                                            <i className="fas fa-search"></i> Pesquisar
                                        </button>
                                        <Link to="/reservas/nova">
                                            <button className="btn btn-success" type="button">
                                                <i className="fas fa-plus"></i> Nova
                                            </button>
                                        </Link>
                                        <Link to="/reservas/importar">
                                            <button className="btn btn-success" type="button">
                                                <i className="fas fa-download"></i> Importar
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label>De:</label>
                                <input
                                    id="ShipDateInitial"
                                    name="ShipDateInitial"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-2">
                                <label>Até:</label>
                                <input
                                    id="ShipDateFinish"
                                    name="ShipDateFinish"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label>Situação:</label>
                                <select
                                    className="form-control"
                                    id="Status"
                                    name="Status"
                                    onChange={handleOnChange}
                                >
                                    <option value={STATUS_IMPORTED_SAP}>Importada SAP</option>
                                    <option value={ALL}>Todas</option>
                                    <option value={STATUS_CONFERENCE}>
                                        Em conferência (separação)
                                    </option>
                                    <option value={STATUS_WAITING_FOR_INVOCE}>
                                        Aguardando emissão da nota fiscal
                                    </option>
                                    <option value={STATUS_IN_TRANSIT}>Em trânsito</option>
                                    <option value={STATUS_DELIVERED}>Entregue</option>
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label>Forma de envio:</label>
                                <select
                                    className="form-control"
                                    id="ShippingWay"
                                    name="ShippingWay"
                                    onChange={handleOnChange}
                                >
                                    <option value={ALL}>Todas</option>
                                    <option value={SHIPPING_IN_PROCESS}>Em processo</option>
                                    <option value={SHIPPING_POST_OFFICES}>Correios</option>
                                    <option value={SHIPPING_SHIPPING_COMPANY}>
                                        Transportadora
                                    </option>
                                    <option value={SHIPPING_POUCH}>Malote</option>
                                    <option value={SHIPPING_COLLECT}>Coleta</option>
                                    <option value={SHIPPING_MATRIZ}>Matriz</option>
                                </select>
                            </div>
                            <div className="form-group col-md-2">
                                <label>Complementares:</label>
                                <select
                                    className="form-control"
                                    id="Complement"
                                    name="Complement"
                                    onChange={handleOnChange}
                                >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                    <option value="2">Todas</option>
                                </select>
                            </div>
                        </div>

                        {hasInvoices &&
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="alert alert-danger" role="alert">
                                        {hasInvoices}&nbsp;<Link to="reservas/nfe">Verifique</Link>
                                    </div>
                                </div>
                            </div>}

                        {orders.message !== "" && orders.message !== undefined &&
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="alert alert-success" role="alert">
                                        {orders.message}
                                    </div>
                                </div>
                            </div>}

                        {loading &&
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="alert alert-success" role="alert">
                                        Aguarde...
                                    </div>
                                </div>
                            </div>}

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <DataTable
                                    columns={columns}
                                    data={orders.data}
                                    noDataComponent={""}
                                    defaultSortField="name"
                                    customStyles={customStyles}
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover
                                    striped
                                    pointerOnHover
                                    dense
                                />
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </App>
    );
}
