import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import api from "../../Services/api";
import { customStyles, paginationOptions } from "../../Shared/config";

export default function Translloyds(props) {

  const [trackingTranslloyds, setTrackingTranslloyds] = useState({
    success: false,
    message: "",
    data: [],
  });

  const url = `/v1/translloyds/tracking/${props.match.params.invoiceNumber}/${props.match.params.serie}`;
  useEffect(() => {
    let ignore = false;
    const translloyds = async () => {
      const response = await api.get(url);
      if (!ignore) setTrackingTranslloyds(response.data)
    };
    translloyds();
    return () => { ignore = true; };
  }, [url]);

  const columnsTrackingTranslloyds = [
    {
      name: "Data",
      selector: "dataOcorrencia",
      sortable: false,
    },
    {
      name: "Hora",
      selector: "horaOcorrencia",
      sortable: false,
    },
    {
      name: "Ocorrência",
      selector: "descricaoOcorrencia",
      sortable: false,
    },
    {
      name: "Data prevista",
      selector: "dataPrevista",
      sortable: false,
    },
    {
      name: "Quem recebeu",
      selector: "nomeRecebedor",
      sortable: false,
    },
    {
      name: "Tipo documento",
      selector: "tipoDocumentoRecebedor",
      sortable: false,
    },
    {
      name: "Número",
      selector: "numeroDocumentoRecebedor",
      sortable: false,
    },
  ];

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <DataTable
          columns={columnsTrackingTranslloyds}
          data={trackingTranslloyds.data}
          noHeader
          noDataComponent={""}
          defaultSortField="name"
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationOptions}
          highlightOnHover
          striped
          pointerOnHover
          dense
        />
      </div>
    </div>
  );
}
