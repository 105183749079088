import React, { useState, useEffect } from "react";
import App from "../../Container/app";
import api from "../../Services/api";

export default function Dashboard(props) {
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    async function getItems() {
      try {
        const url = `/v1/dashboard`;
        await api.get(url).then((result) => {
          setDashboard(result.data.data);
        });
      } catch (error) { console.log(error) }
    }
    getItems();
  }, []);

  return (
    <App>
      <div>
        <div className="row dash-row">
          <div className="col-xl-3">
            <div className="stats stats-primary">
              <h3 className="stats-title"> Reservas </h3>
              <div className="stats-content">
                <div className="stats-icon">
                  <i className="fas fa-cart-arrow-down"></i>
                </div>
                <div className="stats-data">
                  <div className="stats-number">{dashboard.bookings}</div>
                  <div className="stats-change">
                    <span className="stats-timeframe">neste mês</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="stats stats-info ">
              <h3 className="stats-title"> Em aberto </h3>
              <div className="stats-content">
                <div className="stats-icon">
                  <i className="fas fa-cart-arrow-down"></i>
                </div>
                <div className="stats-data">
                  <div className="stats-number">{dashboard.bookingsOpen}</div>
                  <div className="stats-change">
                    <span className="stats-timeframe">importadas e em conferência</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="stats stats-success ">
              <h3 className="stats-title"> Atendidas </h3>
              <div className="stats-content">
                <div className="stats-icon">
                  <i className="fas fa-cart-arrow-down"></i>
                </div>
                <div className="stats-data">
                  <div className="stats-number">{dashboard.bookingsOk}</div>
                  <div className="stats-change">
                    <span className="stats-timeframe">em trânsito e entregue</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3">
            <div className="stats stats-danger">
              <h3 className="stats-title"> Pendentes </h3>
              <div className="stats-content">
                <div className="stats-icon">
                  <i className="fas fa-cart-arrow-down"></i>
                </div>
                <div className="stats-data">
                  <div className="stats-number">{dashboard.bookingsPending}</div>
                  <div className="stats-change">
                    <span className="stats-timeframe">com itens pendentes</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
          </div>
        </div>
      </div>
    </App>
  );
}
