import React, { useState } from "react";
import { BsSearch, BsPlus, BsFolderSymlink } from "react-icons/bs";
import DataTable from 'react-data-table-component';

import App from "../../Container/app";
import Api from "../../Services/api";

import { customStyles, paginationOptions } from "../../Shared/config";

import {
    InputGroup,
    Form,
    FormControl,
    Button,
    Col,
    Card,
    Spinner
} from "react-bootstrap";

export default function UserGroup(props) {
    const [isLoading, setLoading] = useState(false);

    const [fieldSearch, setFieldSearch] = useState("id");
    const handleChangeFieldSearch = (e) => {
        e.persist();
        setFieldSearch(e.target.value);
    };

    const [textSearch, setTextSearch] = useState("");
    const handleChangeTextSearch = (e) => {
        e.persist();
        setTextSearch(e.target.value);
    };

    const [resultPost, setResultPost] = useState({ success: true, message: "", data: [] });

    const handleGetData = async () => {
        setLoading(true);
        var condition = "?" + fieldSearch + "=" + textSearch;
        const response = await Api.get(`/v1/user-groups/all${condition.toLowerCase()}`);
        setLoading(false);
        setResultPost({
            success: response.data.success,
            message: response.data.message,
            data: response.data.data,
        });
    };

    function handleNew() {
        props.history.push({ pathname: "usergroups/form/" });
        return false;
    }

    function handleEdit(id) {
        props.history.push({ pathname: "usergroups/form/" + id });
        return false;
    }

    const ButtonEdit = (Props) => (
        <button onClick={() => { handleEdit(Props.id); }} className="btn btn-light">
            <BsFolderSymlink /> Editar
        </button>
    );

    const columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
            grow: 0,
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: (row) => <ButtonEdit id={row.id}></ButtonEdit>,
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    return (
        <App>
            <Card>
                <Card.Header><b>Grupos de usuários</b></Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Row>
                            <Col sm={2}>
                                <Form.Group controlId="fieldSearch">
                                    <Form.Control as="select" onChange={handleChangeFieldSearch}>
                                        <option value="id">Pelo código</option>
                                        <option value="name">Pelo nome</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={10}>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder="Digite um texto para pesquisa..."
                                        aria-label="Pesquisar"
                                        aria-describedby="btnSearch"
                                        onChange={handleChangeTextSearch}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={handleGetData} variant="secondary">
                                            <BsSearch /> Pesquisar
                                        </Button>
                                        <Button onClick={handleNew} variant="secondary">
                                            <BsPlus /> Novo
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Row>
                        {isLoading ? (<Spinner animation="border" size="sm" />) : ("")}
                    </Form>
                    {resultPost.data && (
                        <DataTable
                            columns={columns}
                            data={resultPost.data}
                            customStyles={customStyles}
                            noHeader
                            noDataComponent={""}
                            defaultSortField="name"
                            pagination
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover
                            striped
                            pointerOnHover
                            dense
                        />
                    )}
                    {resultPost.success
                        ? ""
                        : <div className="text-primary"><strong>{resultPost.message}</strong></div>}
                </Card.Body>
            </Card>
        </App>
    );
}
