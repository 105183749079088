import React, { useState, useEffect } from "react";
import fetchJsonp from "fetch-jsonp";
import { Link } from "react-router-dom";

import { cnpjMask, cepMask } from "../../Shared/masks";
import { RemoveCharactersCNPJCPF } from "../../Shared/utils";

import App from "../../Container/app";
import api from "../../Services/api";

export default function StoreForm(props) {

  const [resultPost, setResultPost] = useState({
    success: false,
    message: "",
    data: [],
  });

  const [store, setStore] = useState({});

  const key_id = props.match.params.id;

  const handleUpdateStore = async (e) => {
    e.preventDefault();
    const data = {
      Id: key_id,
      Name: store.name,
      ShortName: store.shortName,
      Initial: store.initial,
      Street: store.street,
      Number: store.number,
      Complement: store.complement,
      Neighborhood: store.neighborhood,
      ZipCode: store.zipCode,
      City: store.city,
      Uf: store.uf,
      Email: store.email,
      Cnpj: store.cnpj,
      Telephone: store.telephone,
      CellPhone: store.cellphone,
      IE: store.ie,
      IM: store.im,
      CreatedByUserId: 1
    };
    if (key_id === undefined) {
      const result = await api.post("/v1/stores", data);
      setResultPost(result.data);
      setStore(result.data.data);
    } else {
      const result = await api.put("/v1/stores", data);
      setResultPost(result.data);
      setStore(result.data.data);
    }
  };

  useEffect(() => {
    async function getItems() {
      const url = `/v1/stores/${key_id}`;
      const result = await api.get(url)
      setStore(result.data.data);
    }
    if (key_id > 0) getItems();
  }, [key_id]);

  const onChange = (e) => {
    e.persist();
    setStore({ ...store, [e.target.id]: e.target.value });
  };

  //Mask
  const setCep = (value) => {
    const maskedCEP = cepMask(value);
    setStore({ ...store, zipCode: maskedCEP });
  };
  const setCNPJ = (value) => {
    const maskedCNPJ = cnpjMask(value);
    setStore({ ...store, cnpj: maskedCNPJ });
  };

  const handleGetDataOnReceitaWS = (e) => {
    e.preventDefault();
    var cnpj = RemoveCharactersCNPJCPF(store.cnpj);
    const urlReceitaWS = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;
    fetchJsonp(urlReceitaWS)
      .then((res) => res.json())
      .then((res) => {
        setStore({
          id: store.id,
          name: res.nome,
          shortName: res.fantasia !== "" ? res.fantasia : store.shortName,
          initial: store.initial,
          street: res.logradouro,
          number: res.numero,
          complement: res.complemento,
          neighborhood: res.bairro,
          zipCode: res.cep,
          city: res.municipio,
          uf: res.uf,
          email: res.email,
          cnpj: store.cnpj,
          telephone: res.telefone,
          ie: store.ie,
          im: store.im,
        });
      });
  };

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="fas fa-home"></i>&nbsp;Agência/loja
          </div>
        </div>
        <div className="card-body ">
          <form onSubmit={handleUpdateStore}>
            <div className="form-group mb-2">
              <label>Id :</label>
              <input
                disabled
                type="text"
                className="form-control"
                id="id"
                value={store.id ?? ''}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Nome:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={store.name ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-5">
                  <label>Nome fantasia:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="shortName"
                    value={store.shortName ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-1">
                  <label>SIGLA:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="initial"
                    value={store.initial ?? ''}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Endereço:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="street"
                    value={store.street ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label>Número:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    value={store.number ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Complemento:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="complement"
                    value={store.complement ?? ''}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-5">
                  <label>Bairro:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="neighborhood"
                    value={store.neighborhood ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label>CEP:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipCode"
                    value={store.zipCode ?? ''}
                    onChange={(e) => setCep(e.target.value)}
                    placeholder="99.999-999"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Cidade:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={store.city ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label>UF:</label>
                  <select
                    className="form-control"
                    id="uf"
                    value={store.uf ?? ''}
                    onChange={onChange}
                  >
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espirito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={store.email ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Telefone:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="telephone"
                    maxLength="20"
                    value={store.telephone ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Celular:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="cellPhone"
                    maxLength="20"
                    value={store.cellPhone ?? ''}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label>CNPJ:</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="cnpj"
                      value={store.cnpj ?? ''}
                      onChange={(e) => setCNPJ(e.target.value)}
                      placeholder="99.999.999/9999-99"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="btnReceitaWS"
                        onClick={handleGetDataOnReceitaWS}>
                        Busca dados
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label>Inscrição municipal:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="im"
                    value={store.im ?? ''}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Inscrição estadual:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="ie"
                    value={store.ie ?? ''}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-success">
                  <i className="fas fa-save"></i> Gravar
                </button>
                <Link to="/lojas">
                  <button type="submit" className="btn btn-success">
                    <i className="fas fa-arrow-left"></i> Voltar
                  </button>
                </Link>
              </div>
              <div className="col text-danger text-right">
                {resultPost.message}
                {resultPost.success
                  ? ""
                  : resultPost?.data?.map((item, i) => (
                    <div key={i}>{item.message}</div>
                  ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    </App>
  );
}
