import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import api from "../../Services/api";
import App from "../../Container/app";
import { customStyles, paginationOptions } from "../../Shared/config";

import { Button, Modal } from "react-bootstrap";

export default function OrderToInvoiceCreate(props) {
    const [isLoading, setLoading] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [fieldSearch, setFieldSearch] = useState("agency");
    const [orders, setOrders] = useState([]);
    const [extraFields, setExtraFields] = useState({
        ShipDateInitial: "",
        ShipDateFinish: "",
    });
    const [invoiceData, setInvoiceData] = useState({
        shippingCompanyId: "",
        volumeQuantity: "",
        volumeDescription: "",
        weight: "",
        comments: "",
        shippingMode: ""
    });
    const [shippingCompany, setShippingCompany] = useState([]);

    const [resultPost, setResultPost] = useState({
        success: true,
        message: "",
        data: [],
    });

    useEffect(() => {
        let ignore = false;
        const carriers = async () => {
            const response = await api.get(`/v1/shippingcompanies/all`);
            if (!ignore) setShippingCompany(response.data.data);
        };
        carriers();
        return () => {
            ignore = true;
        };
    }, []);

    const handleGetData = async () => {
        setLoading(true);
        var condition = "?like=true&" + fieldSearch + "=" + textSearch;
        if (
            extraFields.ShipDateInitial != null &&
            extraFields.ShipDateFinish != null
        ) {
            condition += "&ShipDateInitial=" + extraFields.ShipDateInitial;
            condition += "&ShipDateFinish=" + extraFields.ShipDateFinish;
        }

        const response = await api.get(
            `/v1/orders/invoices-booking${condition.toLowerCase()}`
        );
        const data = response.data;
        setOrders(data);
        setLoading(false);
    };

    const handleChangeTextSearch = (e) => {
        e.persist();
        setTextSearch(e.target.value);
    };

    const handleChangeFieldSearch = (e) => {
        e.persist();
        setFieldSearch(e.target.value);
    };

    const handleOnChange = (e) => {
        e.persist();
        setExtraFields({ ...extraFields, [e.target.name]: e.target.value });
    };

    const columns = [
        {
            name: "Reserva",
            selector: "bookingId",
            sortable: true,
            grow: 1,
        },
        {
            name: "Departamento",
            selector: "departament",
            sortable: true,
            grow: 0,
        },
        {
            name: "Agência",
            selector: "agencyId",
            sortable: true,
            grow: 0,
        },
        {
            name: "Loja",
            selector: "storeId",
            sortable: true,
            grow: 0,
        },
        {
            name: "Id Requisitante",
            selector: "requesterId",
            sortable: false,
            grow: 0,
        },
        {
            name: "Requisitante",
            selector: "requesterName",
            sortable: false,
        },
        {
            name: "Data reserva",
            selector: "shipDate",
            sortable: true,
            cell: (row) => row.shipDate,
        }
    ];

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedOrders, setSelectedOrders] = React.useState([]);

    const handleRowSelected = React.useCallback((state) => {
        setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = React.useMemo(() => {
        var result = selectedRows.map((r) => ({
            Id: r.id,
            BookingId: r.bookingId,
            Departament: r.departament,
            Agency: r.agencyId ? r.agencyId : "",
            Store: r.storeId ? r.storeId : "",
            Requester: r.requesterId ? r.requesterId : "",
        }));
        setSelectedOrders(result);
    }, [selectedRows]);

    const handleCreateInvoice = async () => {
        const data = {
            Orders: selectedOrders,
            ShippingCompanyId: invoiceData.shippingCompanyId,
            VolumeQuantity: invoiceData.volumeQuantity,
            VolumeDescription: invoiceData.volumeDescription,
            Weight: invoiceData.weight,
            Comments: invoiceData.comments,
            ShippingMode: invoiceData.shippingMode,
            CreatedByUserId: 1,
        };
        const response = await api.post("/v1/invoices/create", data);
        const dataResponse = response.data
        setResultPost(dataResponse);
    }

    const onChange = (e) => {
        e.persist();
        setInvoiceData({ ...invoiceData, [e.target.name]: e.target.value });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <App>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Gerando a nota fiscal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Transportadora:</label>
                                <select
                                    className="form-control"
                                    id="shippingCompanyId"
                                    name="shippingCompanyId"
                                    onChange={onChange}
                                >
                                    <option key="0" value="">Escolha uma transportadora</option>
                                    {shippingCompany.map((i) => (
                                        <option key={i.id} value={i.id}>
                                            {i.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Especie:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="volumeDescription"
                                    name="volumeDescription"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>Quantidade de volumes:</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    id="volumeQuantity"
                                    name="volumeQuantity"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label>Peso (Bruto e Liquido):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="weight"
                                    name="weight"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Informações complementares:</label>
                                <textarea
                                    className="form-control"
                                    id="comments"
                                    name="comments"
                                    rows="3"
                                    onChange={onChange}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Modalidade do frete:</label>
                                <select
                                    className="form-control"
                                    id="shippingMode"
                                    name="shippingMode"
                                    onChange={onChange}
                                >
                                    <option key="0" value="0">Contratação do Frete por conta do Remetente (CIF)</option>
                                    <option key="1" value="1">Contratação do Frete por conta do Destinatário (FOB)</option>
                                    <option key="2" value="2">Contratação do Frete por conta de Terceiros</option>
                                    <option key="3" value="3">Transporte Próprio por conta do Remetente</option>
                                    <option key="4" value="4">Transporte Próprio por conta do Destinatário</option>
                                    <option key="9" value="9">Sem Ocorrência de Transporte</option>
                                </select>
                            </div>
                        </div>
                        <div className="col text-danger text-right">
                            {resultPost.message}
                            {resultPost.data &&
                                <table className="table table-sm table-bordered">
                                    <tbody>
                                        {resultPost?.data?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item.campo}</td>
                                                <td>{item.mensagem}</td>
                                            </tr>))}
                                    </tbody>
                                </table>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="danger" disabled={isLoading} onClick={!isLoading ? handleCreateInvoice : null}>
                        {isLoading ? 'Enviando…' : 'Gerar nota fiscal'}
                    </Button>
                    <Button variant="success" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="card spur-card">
                <div className="card-header bg-success text-white">
                    <div className="spur-card-title">
                        <i className="fas fa-clipboard" />
                        &nbsp;Reservas aguardando emissão de notas fiscais
                    </div>
                </div>
                <div className="card-body ">
                    <form className="form">
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label>Campo:</label>
                                <select
                                    className="form-control"
                                    id="field"
                                    name="field"
                                    onChange={handleChangeFieldSearch}
                                >
                                    <option value="agency">Agência (sigla)</option>
                                    <option value="departament">Departamento (sigla)</option>
                                    <option value="store">Loja (sigla)</option>
                                    <option value="requestedId">Matricula requisitante</option>
                                    <option value="bookingId">Reserva</option>
                                </select>
                            </div>
                            <div className="form-group col-md-10">
                                <label>Texto:</label>
                                <div className="input-group">
                                    <input
                                        id="textSearch"
                                        type="text"
                                        className="form-control"
                                        onChange={handleChangeTextSearch}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            onClick={handleGetData}
                                            className="btn btn-success"
                                            type="button"
                                        >
                                            <i className="fas fa-search"></i> Pesquisar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <label>Reservas de:</label>
                                <input
                                    id="ShipDateInitial"
                                    name="ShipDateInitial"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-2">
                                <label>Até:</label>
                                <input
                                    id="ShipDateFinish"
                                    name="ShipDateFinish"
                                    type="date"
                                    className="form-control"
                                    onChange={handleOnChange}
                                />
                            </div>
                            <div className="form-group col-md-8 text-right">
                                <button
                                    disabled={selectedOrders.length === 0}
                                    onClick={handleShow}
                                    type="button"
                                    className="btn btn-danger"
                                >
                                    <i className="fas fa-save"></i> Gerar a nota fiscal
                                </button>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                {orders.message !== "" && orders.message !== undefined ? (
                                    <div className="alert alert-success" role="alert">
                                        {orders.message}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <DataTable
                            noHeader
                            columns={columns}
                            data={orders.data}
                            noDataComponent={""}
                            defaultSortField="name"
                            customStyles={customStyles}
                            pagination
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover
                            striped
                            pointerOnHover
                            dense
                            selectableRows
                            contextActions={contextActions}
                            onSelectedRowsChange={handleRowSelected}
                        />
                    </form>
                </div>
            </div>
        </App >
    );
}
