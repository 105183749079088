import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { BiSave } from "react-icons/bi";
import { BsPlus, BsBoxArrowLeft } from "react-icons/bs";

import {
    Form,
    Col,
    Row,
    Button,
    ButtonGroup,
    Spinner,
    Card,
} from "react-bootstrap";

import App from "../../Container/app";
import Api from "../../Services/api";

const userGroupInitialState = {
    id: 0,
    name: "",
    createdByUserId: 0,
    companyId: 0,
    organizationId: 0
}

const UserGroupForm = (props) => {
    const [actionNewRecord, setActionNewRecord] = useState(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [resultPost, setResultPost] = useState({
        success: true,
        message: "",
        data: [],
    });

    const [usergroup, setUserGroup] = useState({ ...userGroupInitialState });

    const nameInput = useRef(null);
    let history = useHistory();

    const onChange = (e) => {
        e.persist();
        setUserGroup({ ...usergroup, [e.target.name]: e.target.value });
    };

    const key_id = props.match.params.id;
    useEffect(() => {
        async function getUserGroups() {
            try {
                setLoading(true);
                const url = `/v1/user-groups/${key_id}`;
                await Api.get(url).then((result) => {
                    setUserGroup(result.data.data);
                    setActionNewRecord(false);
                });
                setLoading(false);
            } catch (error) {
                setResultPost({
                    success: false,
                    message: error.message,
                    data: null,
                });
            }
        }
        if (key_id > 0) getUserGroups();
    }, [key_id]);

    const handleOnSaveClick = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const valid = form.checkValidity();
        setValidated(true);

        if (valid) {
            setResultPost({ success: false, message: "", data: null });
            setLoading(true);
            const dataForm = {
                Id: usergroup.id,
                Name: usergroup.name,
                CreatedByUserId: 1,
                CompanyId: 1,
                OrganizationId: 1,
            };
            if (actionNewRecord) {
                const result = await Api.post("/v1/user-groups", dataForm);
                setResultPost({
                    success: result.data.success,
                    message: result.data.message,
                    data: result.data.data,
                });
                if (result.data.success === true) {
                    setActionNewRecord(false);
                    setUserGroup(result.data.data);
                }
            } else {
                const result = await Api.put("/v1/user-groups", dataForm);
                setResultPost({
                    success: result.data.success,
                    message: result.data.message,
                    data: result.data.data,
                });
            }
        }
        setLoading(false);
    };

    const handleNewOnClick = () => {
        setActionNewRecord(true);
        setUserGroup(userGroupInitialState);
        setResultPost({ success: true, message: "", data: null });
        nameInput.current.focus();
    };

    return (
        <App>
            <Card>
                <Card.Header><b>Grupos de usuários</b></Card.Header>
                <Card.Body>
                    <Form noValidate validated={validated} onSubmit={handleOnSaveClick}>
                        <Form.Row>
                            {actionNewRecord ? ("") : (
                                <Form.Group as={Col} md="12" controlId="id">
                                    <Form.Label>Código:</Form.Label>
                                    <Form.Control
                                        value={usergroup.id}
                                        disabled
                                        type="text"
                                        name="id"
                                    />
                                </Form.Group>
                            )}
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="name">
                                <Form.Label>Nome:</Form.Label>
                                <Form.Control
                                    required
                                    value={usergroup.name}
                                    type="text"
                                    maxLength="100"
                                    name="name"
                                    ref={nameInput}
                                    onChange={onChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Você precisa informar o nome do grupo de usuário.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Row>
                            <Col xs={8}>
                                {isLoading ? (<Spinner animation="border" size="sm" />) : ("")}
                                {resultPost.success ?
                                    (
                                        <div className="text-success">
                                            <strong>{resultPost.message}</strong>
                                        </div>
                                    ) : (
                                        <div className="text-danger">
                                            <strong>{resultPost.message}</strong>
                                        </div>
                                    )
                                }
                                {resultPost.success ?
                                    ""
                                    : resultPost?.data?.map((item, i) => (
                                        <div className="text-primary" key={i}>
                                            <strong>
                                                {"(" + item.property + ") " + item.message}
                                            </strong>
                                        </div>
                                    ))
                                }
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <ButtonGroup>
                                        <Button
                                            variant="secondary"
                                            type="submit"
                                            disabled={isLoading ? true : false}>
                                            <BiSave /> Gravar
                                        </Button>
                                        <Button
                                            disabled={actionNewRecord}
                                            variant="secondary"
                                            type="button"
                                            onClick={handleNewOnClick}>
                                            <BsPlus /> Novo
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => { history.push("/usergroups"); }}>
                                            <BsBoxArrowLeft /> Voltar
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </App>
    );
};

export default UserGroupForm;
