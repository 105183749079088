import React, { useState, useEffect } from "react";
import fetchJsonp from "fetch-jsonp";
import { Row, Col, Nav, Tab } from "react-bootstrap";

import App from "../../Container/app";
import api from "../../Services/api";

import { RemoveCharactersCNPJCPF } from "../../Shared/utils";

export default function ConfigurationFormEdit() {

  const [configuration, setConfiguration] = useState({});

  const [resultPost, setResultPost] = useState({
    success: false,
    message: "",
    data: [],
  });

  const handleUpdateConfiguration = (e) => {
    e.preventDefault();
    setResultPost({ success: false, message: "", data: [] });
    api.put("/v1/configurations", configuration).then((result) => {
      setResultPost({
        success: result.data.success,
        message: result.data.message,
        data: result.data.data,
      });
    });
  };

  const handleWebHooks = async (e) => {
    e.preventDefault();
    setResultPost({ success: false, message: "", data: [] });
    var result = await api.post("/v1/invoices/webhooks/create");
    setConfiguration({ ...configuration, hooksId: result.data.id });
  };

  const url = `/v1/configurations`;
  useEffect(() => {
    const GetData = async () => {
      const response = await api.get(url);
      console.log(response.data.data);
      setConfiguration(response.data.data);
    };
    GetData();
  }, [url]);

  const onChange = (e) => {
    e.persist();
    setConfiguration({ ...configuration, [e.target.id]: e.target.value });
  };

  const handleGetDataOnReceitaWS = (e) => {
    e.preventDefault();
    var id = configuration.id;
    var cnpj = RemoveCharactersCNPJCPF(configuration.cnpj);
    const urlReceitaWS = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`;
    fetchJsonp(urlReceitaWS)
      .then((res) => res.json())
      .then((res) => {
        setConfiguration({
          id: id,
          name: res.nome,
          street: res.logradouro,
          number: res.numero,
          complement: res.complemento,
          neighborhood: res.bairro,
          zipCode: res.cep,
          city: res.municipio,
          uf: res.uf,
          email: res.email,
          cnpj: configuration.cnpj,
          telephone: res.telefone,
          ie: configuration.ie,
          im: configuration.im,
        });
      });
  };

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="fas fa-users-cog"></i>&nbsp;Configurações Gerais
          </div>
        </div>
        <div className="card-body ">
          <form onSubmit={handleUpdateConfiguration}>
            <Tab.Container id="tabConfiguration" defaultActiveKey="company">
              <Row>
                <Col sm={2}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="company">Empresa</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="invoice">Fiscal</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="system">Sistema</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={10}>
                  <Tab.Content>
                    <Tab.Pane eventKey="company">
                      <div className="form-group">
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label>Nome:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              value={configuration.name}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Endereço:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="street"
                              value={configuration.street}
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label>Número:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="number"
                              value={configuration.number}
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Complemento:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="complement"
                              value={configuration.complement}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Bairro:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="neighborhood"
                              value={configuration.neighborhood}
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group col-md-1">
                            <label>CEP:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipCode"
                              value={configuration.zipCode}
                              onChange={onchange}
                              placeholder="99.999-999"
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label>Cidade:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              value={configuration.city}
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label>UF:</label>
                            <select
                              className="form-control"
                              id="uf"
                              value={configuration.uf}
                              onChange={onChange}
                            >
                              <option value="AC">Acre</option>
                              <option value="AL">Alagoas</option>
                              <option value="AP">Amapá</option>
                              <option value="AM">Amazonas</option>
                              <option value="BA">Bahia</option>
                              <option value="CE">Ceará</option>
                              <option value="DF">Distrito Federal</option>
                              <option value="ES">Espirito Santo</option>
                              <option value="GO">Goiás</option>
                              <option value="MA">Maranhão</option>
                              <option value="MS">Mato Grosso do Sul</option>
                              <option value="MT">Mato Grosso</option>
                              <option value="MG">Minas Gerais</option>
                              <option value="PA">Pará</option>
                              <option value="PB">Paraíba</option>
                              <option value="PR">Paraná</option>
                              <option value="PE">Pernambuco</option>
                              <option value="PI">Piauí</option>
                              <option value="RJ">Rio de Janeiro</option>
                              <option value="RN">Rio Grande do Norte</option>
                              <option value="RS">Rio Grande do Sul</option>
                              <option value="RO">Rondônia</option>
                              <option value="RR">Roraima</option>
                              <option value="SC">Santa Catarina</option>
                              <option value="SP">São Paulo</option>
                              <option value="SE">Sergipe</option>
                              <option value="TO">Tocantins</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Email:</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              value={configuration.email}
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label>Telefone:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="telephone"
                              value={configuration.telephone}
                              onChange={onchange}
                              placeholder="(99) 99999-9999"
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label>Celular:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="cellPhone"
                              value={configuration.cellPhone}
                              onChange={onchange}
                              placeholder="(99) 99999-9999"
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label>CNPJ:</label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="cnpj"
                                value={configuration.cnpj}
                                onChange={onchange}
                                placeholder="99.999.999/9999-99"
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  id="btnReceitaWS"
                                  onClick={handleGetDataOnReceitaWS}
                                >
                                  Busca dados
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-4">
                            <label>Inscrição municipal:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="im"
                              value={configuration.im}
                              onChange={onChange}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Inscrição estadual:</label>
                            <input
                              type="text"
                              className="form-control"
                              id="ie"
                              value={configuration.ie}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="invoice">
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label>Último número de referência nota fiscal:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastInvoiceReference"
                            value={configuration.lastInvoiceReference}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>Nota fiscal:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="invoiceNumber"
                            value={configuration.invoiceNumber}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>Serie:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="invoiceSerie"
                            value={configuration.invoiceSerie}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label>Informações complementares na nota:</label>
                          <textarea
                            className="form-control"
                            id="comments"
                            rows="3"
                            value={configuration.comments}
                            onChange={onChange}
                          ></textarea>
                        </div>
                        <div className="form-group col-md-9"></div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label>Informações complementares na nota dentro do Estado (MG):</label>
                          <textarea
                            className="form-control"
                            id="commentsInState"
                            rows="3"
                            value={configuration.commentsInState}
                            onChange={onChange}
                          ></textarea>
                        </div>
                        <div className="form-group col-md-9"></div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label>Informações complementares na nota fora do Estado (MG):</label>
                          <textarea
                            className="form-control"
                            id="commentsOutState"
                            rows="3"
                            value={configuration.commentsOutState}
                            onChange={onChange}
                          ></textarea>
                        </div>
                        <div className="form-group col-md-9"></div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Token Homologação:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="tokenHomologation"
                            value={configuration.tokenHomologation}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Token Produção:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="tokenProduction"
                            value={configuration.tokenProduction}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label>Envento do WebHooks NFe:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="eventId"
                            value={configuration.eventId}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>URL do WebHooks NFe:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="urlHooks"
                            value={configuration.urlHooks}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label>WebHooks NFe:</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="hooksId"
                              value={configuration.hooksId}
                              onChange={onChange}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handleWebHooks}
                              >WebHooks</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="system">
                      <div className="form-row">
                        <div className="form-group col-md-3">
                          <label>Lote de importação de reservas:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastIdLotNumber"
                            value={configuration.lastIdLotNumber}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-9"></div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Servidor do email:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="emailServer"
                            value={configuration.emailServer}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Porta:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="emailPort"
                            value={configuration.emailPort}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Usuário:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="emailUser"
                            value={configuration.emailUser}
                            onChange={onChange}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Senha:</label>
                          <input
                            type="password"
                            className="form-control"
                            id="emailPassword"
                            value={configuration.emailPassword}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-success">
                  <i className="fas fa-save"></i> Gravar
                </button>
              </div>
              <div className="col text-danger text-right">
                {resultPost.message}
                {resultPost.success
                  ? ""
                  : resultPost?.data?.map((item, i) => (
                    <div key={i}>{item.message}</div>
                  ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    </App>
  );
}
