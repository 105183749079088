import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { BiSave } from "react-icons/bi";
import { BsPlus, BsBoxArrowLeft } from "react-icons/bs";

import {
    Form,
    Col,
    Row,
    Button,
    ButtonGroup,
    Spinner,
    Card,
} from "react-bootstrap";

import App from "../../Container/app";
import Api from "../../Services/api";

const UserForm = (props) => {
    const [actionNewRecord, setActionNewRecord] = useState(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [resultPost, setResultPost] = useState({
        success: true,
        message: "",
        data: [],
    });

    const [selectUserGroups, setSelectUserGroups] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const response = await Api.get(`/v1/user-groups/all-to-select`);
            setSelectUserGroups(response.data.data);
        };
        fetchData();
    }, []);

    const [user, setUser] = useState({});

    const userReset = () => {
        setUser({
            id: 0,
            name: "",
            userGroupId: 0,
            nivel: 0,
            login: "",
            password: "",
            confirmPassword: "",
            active: 0,
            bluetoothPrinter: "",
            createdByUserId: 0,
            companyId: 0,
            organizationId: 0
        });
    };

    const nameInput = useRef(null);
    let history = useHistory();

    const onChange = (e) => {
        e.persist();
        setUser({ ...user, [e.target.id]: e.target.value });
    };

    const key_id = props.match.params.id;
    useEffect(() => {
        async function getUsers() {
            try {
                setLoading(true);
                const url = `/v1/users/${key_id}`;
                await Api.get(url).then((result) => {
                    setUser(result.data.data);
                    setActionNewRecord(false);
                });
                setLoading(false);
            } catch (error) {
                setResultPost({
                    success: false,
                    message: error.message,
                    data: null,
                });
            }
        }
        if (key_id > 0) getUsers();
    }, [key_id]);

    const handleOnSaveClick = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const valid = form.checkValidity();
        setValidated(true);

        if (valid) {
            setResultPost({ success: false, message: "", data: null });
            setLoading(true);

            const dataForm = {
                Id: user.id,
                Name: user.name,
                UserGroupId: user.userGroupId,
                Nivel: user.nivel,
                Login: user.login,
                Password: user.password,
                ConfirmPassword: user.confirmPassword,
                Active: user.active,
                BluetoothPrinter: user.bluetoothPrinter,
                CreatedByUserId: 1,
                CompanyId: 1,
                OrganizationId: 1
            };

            if (actionNewRecord) {
                const result = await Api.post("/v1/users", dataForm);
                setResultPost({
                    success: result.data.success,
                    message: result.data.message,
                    data: result.data.data,
                });
                if (result.data.success === true) {
                    setActionNewRecord(false);
                    setUser(result.data.data);
                }
            } else {
                const result = await Api.put("/v1/users", dataForm);
                setResultPost({
                    success: result.data.success,
                    message: result.data.message,
                    data: result.data.data,
                });
            }
        }
        setLoading(false);
    };

    const handleNewOnClick = () => {
        setActionNewRecord(true);
        userReset();
        setResultPost({ success: true, message: "", data: null });
        nameInput.current.focus();
    };

    return (
        <App>
            <Card>
                <Card.Header><b>Usuários</b></Card.Header>
                <Card.Body>
                    <Form noValidate validated={validated} onSubmit={handleOnSaveClick}>
                        <Form.Row>
                            {actionNewRecord ? ("") : (
                                <Form.Group as={Col} md="12" controlId="id">
                                    <Form.Label>Código:</Form.Label>
                                    <Form.Control
                                        value={user.id}
                                        disabled
                                        type="text"
                                    />
                                </Form.Group>
                            )}
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="name">
                                <Form.Label>Nome:</Form.Label>
                                <Form.Control
                                    required
                                    value={user.name}
                                    type="text"
                                    maxLength="100"
                                    ref={nameInput}
                                    onChange={onChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Você precisa informar o nome do usuário.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="userGroupId">
                                <Form.Label>Grupo de usuário:</Form.Label>
                                <Form.Control as="select" value={user.userGroupId ?? ''} onChange={onChange}>
                                    {selectUserGroups && selectUserGroups.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="2" controlId="nivel">
                                <Form.Label>Nível:</Form.Label>
                                <Form.Control as="select" value={user.nivel ?? ''} onChange={onChange}>
                                    <option value="0">Administrador</option>
                                    <option value="1">Proprietário</option>
                                    <option value="2">Usuário</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="login">
                                <Form.Label>Login:</Form.Label>
                                {actionNewRecord === true ? (
                                    <>
                                        < Form.Control
                                            required
                                            value={user.login}
                                            type="text"
                                            maxLength="20"
                                            onChange={onChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Você precisa informar o login de usuário.
                                        </Form.Control.Feedback>
                                    </>) : (<Form.Control readOnly type="text" />)
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="password">
                                <Form.Label>Senha:</Form.Label>
                                {actionNewRecord === true ? (
                                    <>
                                        <Form.Control
                                            required
                                            value={user.password}
                                            type="password"
                                            maxLength="20"
                                            onChange={onChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Você precisa informar a senha do usuário.
                                        </Form.Control.Feedback>
                                    </>) : (<Form.Control readOnly type="text" />)
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="confirmPassword">
                                <Form.Label>Confirme a senha:</Form.Label>
                                {actionNewRecord === true ? (
                                    <>
                                        <Form.Control
                                            required
                                            value={user.confirmPassword}
                                            type="password"
                                            maxLength="20"
                                            onChange={onChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Você precisa informar a senha do usuário.
                                        </Form.Control.Feedback>
                                    </>) : (<Form.Control readOnly type="text" />)
                                }
                            </Form.Group>
                            <Form.Group as={Col} md="2" controlId="active">
                                <Form.Label>Ativo:</Form.Label>
                                <Form.Control as="select" value={user.active ?? ''} onChange={onChange}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="bluetoothPrinter">
                                <Form.Label>Impressora Bluetooth:</Form.Label>
                                <Form.Control
                                    value={user.bluetoothPrinter}
                                    type="text"
                                    maxLength="100"
                                    onChange={onChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Row>
                            <Col xs={8}>
                                {isLoading ? (<Spinner animation="border" size="sm" />) : ("")}
                                {resultPost.success ?
                                    (
                                        <div className="text-success">
                                            <strong>{resultPost.message}</strong>
                                        </div>
                                    ) : (
                                        <div className="text-danger">
                                            <strong>{resultPost.message}</strong>
                                        </div>
                                    )
                                }
                                {resultPost.success ?
                                    ""
                                    : resultPost?.data?.map((item, i) => (
                                        <div className="text-primary" key={i}>
                                            <strong>
                                                {"(" + item.property + ") " + item.message}
                                            </strong>
                                        </div>
                                    ))
                                }
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <ButtonGroup>
                                        <Button
                                            variant="secondary"
                                            type="submit"
                                            disabled={isLoading ? true : false}>
                                            <BiSave /> Gravar
                                        </Button>
                                        <Button
                                            disabled={actionNewRecord}
                                            variant="secondary"
                                            type="button"
                                            onClick={handleNewOnClick}>
                                            <BsPlus /> Novo
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => { history.push("/users"); }}>
                                            <BsBoxArrowLeft /> Voltar
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </App>
    );
};

export default UserForm;
