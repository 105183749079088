import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Logo from "../../img/user.png";

export default function Login() {
    const [IsLoggingIn, setIsLoggingIn] = useState(false);

    const history = useHistory();
    const [messageError, setMessageError] = useState("");

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        setIsLoggingIn(true);
        fetch("https://www.fcaexpress.com.br/v1/users/login", {
            method: "POST",
            body: JSON.stringify({ login, password }),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoggingIn(false);
                if (data.success) {
                    localStorage.setItem("user_fca", JSON.stringify(data.data.user));
                    history.push("/");
                } else {
                    setMessageError(data.message);
                    localStorage.removeItem("user_fca");
                }
            });
        event.preventDefault();
    };

    return (
        <div className="form-screen">
            <div className="card account-dialog">
                <div className="card-header bg-success text-white">
                    FCAExpress :: Suprimentos
        </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="text-center mb-4">
                            <img className="mb-4" src={Logo} alt="acesso retritro" />
                            <h1 className="h3 mb-3 font-weight-normal">Acesso restrito</h1>
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control "
                                name="login"
                                placeholder="Usuário"
                                onChange={(e) => setLogin(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control "
                                name="password"
                                placeholder="Senha"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            {messageError !== "" && messageError !== undefined ? (
                                <div className="alert alert-danger" role="alert">
                                    {messageError}
                                </div>
                            ) : (
                                    ""
                                )}
                        </div>
                        <div className="account-dialog-actions">
                            <button type="submit" className="btn btn-success">
                                {IsLoggingIn ? " Validando o usuario..." : " Login"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
