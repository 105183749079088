import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import App from "../../Container/app";
import api from "../../Services/api";

import { customStyles, paginationOptions } from "../../Shared/config";

export default function Requester(props) {
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [fieldSearch, setFieldSearch] = useState("name");

  const [requesters, setRequesters] = useState([]);

  const handleGetData = async () => {
    setLoading(true);
    var condition = "?like=true&" + fieldSearch + "=" + textSearch;
    const response = await api.get(
      `/v1/requesters/all${condition.toLowerCase()}`
    );
    const data = response.data;
    setRequesters(data);
    setLoading(false);
  };

  const Button = (props) => (
    <button
      onClick={() => {
        handleEditRequester(props.id);
      }}
      className="btn btn-md btn-success"
    >
      <i className="fas fa-edit"></i> Editar
    </button>
  );

  const handleChangeTextSearch = (e) => {
    e.persist();
    setTextSearch(e.target.value);
  };

  const handleChangeFieldSearch = (e) => {
    e.persist();
    setFieldSearch(e.target.value);
  };

  const columns = [
    {
      name: "Id",
      selector: "id",
      sortable: false,
      grow: 0,
    },
    {
      name: "Nome",
      selector: "name",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => <Button id={row.id}></Button>,
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const handleEditRequester = (id) => {
    props.history.push({
      pathname: "requisitantes/editar/" + id,
    });
  };

  return (
    <App>
      <div className="card spur-card">
        <div className="card-header bg-success text-white">
          <div className="spur-card-title">
            <i className="fas fa-user-check"></i>&nbsp;Requisitantes
          </div>
        </div>
        <div className="card-body ">
          <form className="form">
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>Campo:</label>
                <select
                  className="form-control"
                  id="field"
                  name="field"
                  onChange={handleChangeFieldSearch}
                >
                  <option value="name">Nome</option>
                  <option value="email">Email</option>
                  <option value="id">Matricula</option>
                </select>
              </div>
              <div className="form-group col-md-10">
                <label>Texto:</label>
                <div className="input-group">
                  <input
                    id="textSearch"
                    type="text"
                    className="form-control"
                    onChange={handleChangeTextSearch}
                  />
                  <div className="input-group-append">
                    <button
                      onClick={handleGetData}
                      className="btn btn-success"
                      type="button"
                    >
                      <i className="fas fa-search"></i> Pesquisar
                    </button>
                    <Link to="/requisitantes/novo">
                      <button className="btn btn-success" type="button">
                        <i className="fas fa-plus"></i> Novo
                      </button>
                    </Link>
                    <Link to="/requisitantes/sincronizar">
                      <button className="btn btn-success" type="button">
                        <i className="fas fa-sync"></i> Sincronizar
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {requesters.message !== "" && requesters.message !== undefined &&
              <div className="form-row">
                <div className="form-group col-md-12">
                  <div className="alert alert-success" role="alert">
                    {requesters.message}
                  </div>
                </div>
              </div>}

            {loading &&
              <div className="form-row">
                <div className="form-group col-md-12">
                  <div className="alert alert-success" role="alert">
                    Aguarde...
                  </div>
                </div>
              </div>}

            <div className="form-row">
              <div className="form-group col-md-12">
                <DataTable
                  columns={columns}
                  data={requesters.data}
                  noDataComponent={""}
                  noHeader
                  defaultSortField="name"
                  customStyles={customStyles}
                  pagination
                  paginationComponentOptions={paginationOptions}
                  highlightOnHover
                  striped
                  pointerOnHover
                  dense
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </App>
  );
}
