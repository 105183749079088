import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import api from "../../Services/api";
import { customStyles, paginationOptions } from "../../Shared/config";

export default function JadLog(props) {

  const [trackings, setTrackings] = useState([{
    data: "",
    status: "",
    unidade: ""
  }]);

  const url = `/v1/jadlog/tracking/${props.match.params.danfe}`;
  useEffect(() => {
    let ignore = false;
    const jadlog = async () => {
      const response = await api.get(url);
      if (!ignore) {
        setTrackings(response.data.data[0].eventos);
      }
    }
    jadlog();
    return () => { ignore = true; };
  }, [url]);

  const columnsTrackingJadLog = [
    {
      name: "Data",
      selector: "data",
      sortable: true,
      cell: (row) =>
        row.data
          ? format(new Date(row.data), "dd/MM/yyyy hh:mm")
          : "",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Unidade",
      selector: "unidade",
      sortable: true,
    },
  ];

  return (
    <div className="form-row">
      <div className="form-group col-12">
        <DataTable
          columns={columnsTrackingJadLog}
          data={trackings}
          noHeader
          noDataComponent={""}
          defaultSortField="name"
          customStyles={customStyles}
          pagination
          paginationComponentOptions={paginationOptions}
          highlightOnHover
          striped
          pointerOnHover
          dense
        />
      </div>
    </div>
  );
}
