import React, { useState } from "react";
import { format } from "date-fns";
import { Container, Button, Modal } from "react-bootstrap";
import { customStyles, paginationOptions } from "../../Shared/config";
import DataTable from "react-data-table-component";
import api from "../../Services/api";
import { StringToCurrency } from "../../Shared/utils";

export default function InvoiceItem({ data }) {
  const [shippingCompanyId, setShippingCompanyId] = useState(0);

  const [showShipping, setShowShipping] = useState(false);

  const handlerShipping = (shippingCompanyId) => {
    setShippingCompanyId(shippingCompanyId);
    shippingCompanyId === 0 ? getTranslloyds() : getJadlog();
    setShowShipping(true);
  }

  const ButtonShipping = (props) => (
    <Button onClick={() => { handlerShipping(props.shippingId); }} className="btn btn-sm btn-success">
      <i className="fas fa-truck-moving"></i> Rastreamento
    </Button>
  );

  const ButtonEmail = (props) => (
    <Button onClick={() => { sendMail(props.reference); }} className="btn btn-sm btn-danger">
      <i className="fas fa-envelope"></i> Reenvia email
    </Button>
  );

  const [sefaz, setSefaz] = useState({
    success: true,
    message: "",
    data: []
  });

  const [translloyds, setTranslloyds] = useState([]);

  const [jadlog, setJadlog] = useState([]);

  const getTranslloyds = async () => {
    const url = `/v1/translloyds/tracking`;
    const d = {
      ShippingCompanyId: 1,
      NumeroNotaFiscal: data.invoiceNumber,
      SerieNotaFiscal: data.invoiceSerie,
    };
    api.post(url, d).then((result) => {
      setTranslloyds(JSON.parse(result.data.data));
    });
  };

  const getJadlog = async () => {
    const url = `/v1/jadlog/tracking/` + data.tracking.replace('/jadlog/', '');
    const result = await api.get(url);
    setJadlog(result.data.data[0]);
  };

  const sendMail = async (reference) => {
    const url = `/v1/invoices/sefaz/` + reference;
    const result = await api.get(url);
    setSefaz(result.data);
  };

  const columnsJadlog = [
    {
      name: "Data",
      selector: "data",
      sortable: true,
      cell: (row) =>
        row.data
          ? format(new Date(row.data), "dd/MM/yyyy hh:mm")
          : "",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Unidade",
      selector: "unidade",
      sortable: true,
    },
  ];

  const columnsTranslloyds = [
    {
      name: "Ocorrência",
      selector: "CodigoOcorrencia",
      sortable: false,
    },
    {
      name: "Descrição",
      selector: "DescricaoOcorrencia",
      sortable: false,
    },
    {
      name: "Data",
      selector: "DataOcorrencia",
      sortable: false,
      cell: (row) =>
        row.DataOcorrencia
          ? format(new Date(row.DataOcorrencia), "dd/MM/yyyy")
          : "",
    },
    {
      name: "Hora",
      selector: "HoraOcorrencia",
      sortable: false,
    },
    {
      name: "Recebedor",
      selector: "NomeRecebedor",
      sortable: false,
    },
    {
      name: "Tipo documento",
      selector: "TipoDocumentoRecebedor",
      sortable: false,
    },
    {
      name: "Número",
      selector: "NumeroDocumentoRecebedor",
      sortable: false,
    },
    {
      name: "Boletim",
      selector: "Boletim",
      sortable: false,
    },
  ];

  return (
    <Container>
      <div><p>Reservas : {data.bookingIds}</p></div>

      <div><p><ButtonShipping shippingId={data.shippingCompanyId}></ButtonShipping></p></div>

      <div><p><ButtonEmail reference={data.id}></ButtonEmail> {sefaz.message}</p></div>

      <Modal
        size="lg"
        show={showShipping}
        onHide={() => setShowShipping(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Rastreamento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {shippingCompanyId > 0 ? (
            <>
              {shippingCompanyId > 1 ?
                <div className="form-row">
                  <div><p>Data da emissão : {jadlog.dtEmissao}</p><p>Status : {jadlog.status}</p></div>
                </div>
                : ""}

              <div className="form-row">
                <div className="form-group col-12">
                  <DataTable
                    columns={shippingCompanyId === 1 ? columnsTranslloyds : columnsJadlog}
                    data={shippingCompanyId === 1 ? translloyds : jadlog.eventos}
                    noHeader
                    noDataComponent={""}
                    defaultSortField="name"
                    customStyles={customStyles}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    highlightOnHover
                    striped
                    pointerOnHover
                    dense
                  />
                </div>
              </div>
            </>
          ) : ("")}
        </Modal.Body>
      </Modal>

      <table className="table table-sm table-bordered">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Código</th>
            <th scope="col">Descrição</th>
            <th scope="col">NCM</th>
            <th scope="col">CST</th>
            <th scope="col">Unidade</th>
            <th scope="col">Quantidade</th>
            <th scope="col">Valor Unitário R$</th>
            <th scope="col">Valor Total R$</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item, i) => (
            <tr key={i}>
              <th className="text-right" scope="row">
                {item.sequency}
              </th>
              <td>{item.productId}</td>
              <td>{item.productDescription}</td>
              <td>{item.productNcm}</td>
              <td>{item.icmsTaxSituation}</td>
              <td>{item.unity}</td>
              <td className="text-right">
                {StringToCurrency(item.quantity, "decimal")}
              </td>
              <td className="text-right">
                {StringToCurrency(item.price, "decimal")}
              </td>
              <td className="text-right">
                {StringToCurrency(item.priceSum, "decimal")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div><p className="text-right">Total dos itens : {StringToCurrency(data.totalValue, "decimal")}</p></div>

    </Container>
  );
}
